<section id="advisor-intro" class="clearfix">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-1 col-sm-1 col-md-1 col-1"></div>
            <div class="col-lg-5 col-md-5 col-sm-12 col-12 my-auto text-sm-center text-md-left text-lg-left text-center">
            <span class="txtadvisor-heading">Become an Insurance Advisor</span>
            <p class="advisor-txtlight">Let us empower customers together</p>                
            <a href="https://pos.gopolicy.com/#/newuser" target="_blank" type="button" class="btn advisor-btn-joinus mt-5">Join Us</a>
            </div>
            <div class="col-lg-5 col-md-5 col-12 text-center mt-sm-5">
                <img class="img-fluid" src="assets/images/Group9177.png" alt="">
            </div>
        </div>
    </div>
</section>
<section id="pospsection">
    <div class="container-fluid">
      <div class="section-title">
        <h2>Who is a POSP?</h2>
      </div>
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-8 col-sm-12 col-12 text-center">
                <span class="advisor-txtlight">
                  A POSP (Point of Sales Person) is a qualified insurance representative who can solicit insurance products to the
                  customers on behalf of the broker/insurance company.</span>
            </div>
        </div>
        <div class="row mt-5 justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12 col-12 text-center">
                <span class="posp-txtdesc">Do you also want to earn money
                    from where you are? Register yourself as a Point of Sales Person (POSP) . Put your
                    ability to sell, and work smart to great use. As a POSP, you will be responsible
                    to ensure customers get the right insurance policies.</span>
            </div>
        </div>
        <div class="row padding-top-bottom-40 justify-content-center">
            <div class="col-lg-4 col-md-4 col-12 text-lg-left text-md-left text-sm-center text-center">
                <span><img class="img-fluid" src="assets/images/Group9117_2.png" alt=""></span>
            </div>
            <div class="col-lg-6 col-md-6 col-12 my-auto">
                <h6 class="txt-who-Posp">Who can be a POSP?</h6>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <ul id="posplist" class="reset-style custom-style-base">
                            <li class="posp-txtdesc">The minimum qualification is 10th standard and above 18 years of age.</li>
                            <li class="posp-txtdesc">A passionate individual who is not a part of any other agency, and willing to grow as the opportunities occur</li>
                            <li class="posp-txtdesc"> Willing to go through our training process, and work with our team of experts.</li>
                          </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="insurance-advisor">
    <div class="container-fluid">
      <div class="section-title">
        <h2>Steps to become and insurance advisor at Gopolicy</h2>
        <p class="mb-4">Zero investment required</p>
      </div>
<div class="container py-2 mt-5 mb-5">
  <!-- timeline item 1 -->
  <div class="row no-gutters">
    <div class="col-sm"> <!--spacer--> </div>
    <!-- timeline item 1 center dot -->
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
</g>
<path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
<defs>
<filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
</span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <!-- timeline item 1 event content -->
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-left">
          <h4 class="card-title text-center timeline-mesg-box-heading">Register</h4>
          <p class="card-text text-center timeline-mesg-box-para">Yourself formally with Gopolicy as the initial step. This will
            bring your profile into our pool of talents, and our experts shall verify your details.</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
  <!-- timeline item 2 -->
  <div class="row no-gutters">
    <div class="col-sm py-2">
      <div class="card shadow">
        <div class="card-body timeline-mesg-box arrow-right">
          <h4 class="card-title timeline-mesg-box-heading text-center">Training</h4>
          <p class="card-text timeline-mesg-box-para text-center">For any job accentuates the existing talent into the practical
            world. Be a part of our extensive training program and prepare yourself to take on the world.</p>
        </div>
      </div>
    </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm"> <!--spacer--> </div>
  </div>
  <!--/row-->
  <!-- timeline item 3 -->
  <div class="row no-gutters">
    <div class="col-sm"> <!--spacer--> </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-left">
          <h4 class="card-title text-center timeline-mesg-box-heading">Assessment</h4>
          <p class="timeline-mesg-box-para text-center">Is carried out by our team of experts, who will ensure you are perfectly
            ready to lead the dynamics of the insurance world.</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
  <!-- timeline item 4 -->
  <div class="row no-gutters">
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-right">
          <h4 class="card-title text-center timeline-mesg-box-heading">Appointment</h4>
          <p class="timeline-mesg-box-para text-center">Is formally carried out, and now you are your own boss.</p>
        </div>
      </div>
    </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm"> <!--spacer--> </div>
  </div>
  <!--/row-->
</div>
</div>
</section>
<section id="faq" class="faq pb-90">
  <div class="container-fluid">
    <div class="section-title">
      <h2>Frequently Asked Questions</h2>
      <p class="mb-5">Feel free to ask us more</p>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-9 col-12">
        <div class="accordion-list">
          <ul>
            <li data-aos="fade-up">
              <a data-toggle="collapse" class="collapse" href="#advisoraccordion-list-1">What products
                  can a POSP sell? <i class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
              <div id="advisoraccordion-list-1" class="collapse show" data-parent=".accordion-list">
                <p>
                  POSP can sell all Life and General Insurance Policies authorized by IRDAI.
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="100">
              <a data-toggle="collapse" href="#advisoraccordion-list-2" class="collapsed">How does a POSP operate? <i
                  class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
              <div id="advisoraccordion-list-2" class="collapse" data-parent=".accordion-list">
                <p>
                  Thanks to technology, most of our processes are digital. So, it is enough if you have a computer system/smart phone
                  and internet to work with.
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="200">
              <a data-toggle="collapse" href="#advisoraccordion-list-3" class="collapsed">Will a certificate be issued upon completion
                of training? <i class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
              <div id="advisoraccordion-list-3" class="collapse" data-parent=".accordion-list">
                <p>
                  Yes. Successful completion of training not only implies employment, but also a certification that you are well
                  qualified to be an expert at Gopolicy.
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <a data-toggle="collapse" href="#advisoraccordion-list-4" class="collapsed">Is an agent required to become a POSP? <i class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
              <div id="advisoraccordion-list-4" class="collapse" data-parent=".accordion-list">
                <p>
                  No. A POSP can join our firm as an individual, and work in his/her own terms. We do not entertain middlemen.
                </p>
              </div>
            </li>
            <li data-aos="fade-up" data-aos-delay="300">
              <a data-toggle="collapse" href="#advisoraccordion-list-5" class="collapsed">What is the insurance legislation in India? <i class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
              <div id="advisoraccordion-list-5" class="collapse" data-parent=".accordion-list">
                <p>
                  The Insurance Act of 1938 provides IRDAI the power to design a regulatory framework for firms and individuals
                  operating in the sector.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

   
  </div>
</section>
<section id="our-experts" class="clearfix mt-5">
  <div class="container-fluid our-experts-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-lg-left text-md-left text-sm-center text-center">
        <img class="img-fluid" src="assets/images/View_Icon.png" alt="">
        </div>
        <div class="col-lg-8 col-md-6 col-sm-12 col-12 mt-sm-4">
          <h5 class="txt-notsure">To get complete details on how to become an Insurance Advisor</h5>
          <div class="row">
            <div class="col-lg-7 col-md-7 col-12">
              <form action="" [formGroup]="advisorcallBackForm">
                <div class="input-group mb-1">
                  <input type="text" class="form-control callback-input" placeholder="Enter your mobile number"
                    aria-label="Recipient's username" aria-describedby="basic-addon2" formControlName="mobile_no" name="mobile_no" maxlength="10" (keypress)="numberOnly($event)">
                  <div class="input-group-append">
                    <button type="button" class="input-group-text" (click)="callBackSubmit();">Get a call back</button>
                  </div>
                </div>
                <div class="text-left ml-2" *ngIf="submitted && q.mobile_no.errors">
                  <div class="alert-meassge ml-2" *ngIf="q.mobile_no.errors.required">Mobile Number is Required.</div>
                  <div class="alert-meassge ml-2" *ngIf="q.mobile_no.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>