
<section id="careers-intro" class="clearfix">
    <div class="container-fluid">
        <div class="row justify-content-around">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12 pl-0 pb-5">
                <div aria-label="breadcrumb">
                    <ol class="breadcrumb careers-breadcrumb">
                        <li class="breadcrumb-item"><a class="unactive" routerLink="">Home</a></li>
                        <li class="breadcrumb-item" aria-current="page"><a class="active" routerLink="/careers">CAREER</a></li>
                    </ol>
                </div>
            </div>
            <div class="col-lg-4 pb-5 col-md-4 col-sm-12 col-12">
            </div>
        </div>
        <div class="row pb-5 justify-content-around">
            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                <h4 class="career-heading">Be a panel expert</h4>
                <p class="careers-homedesc">We have space for anyone ranging from a high school graduate to an
                    experienced professional. Join our enthusiastic and knowledgeable squad and earn what you desire.
                </p>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-12 my-auto">
                <img class="img-fluid" src="assets/images/Group(6).png" alt="">
            </div>
        </div>
    </div>
</section>

<section class="padding-top-bottom-60">
    <div class="container-fluid">
        <h4 class="text-center txt-open-position">Open Positions</h4>
        <p class="vacancies-desc text-center">‘Sorry, No current vacancies. Please check back again soon’</p>
    </div>
</section>
<section class="padding-top-bottom-60">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-10 col-md-10 col-sm-12 col-12">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="row">
                                    <div class="col-lg-10 col-12">
                                        <form action="" class="form-border" [formGroup]="careerForm">
                                            <div class="form-group">
                                                <input type="text" class="form-control careers-input" formControlName="full_name" id="full_name" name="full_name" placeholder="Full Name" [ngClass]="{ 'is-invalid': submitted && f.full_name.errors }"> 
                                                <div class="mt-2 text-left" *ngIf="submitted && f.full_name.errors">
                                                    <div class="alert-meassge" *ngIf="f.full_name.errors.required">FullName is Required</div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control careers-input" id="email_id" name="email_id" formControlName="email_id" placeholder="Email Id" [ngClass]="{ 'is-invalid': submitted && f.email_id.errors }" email>
                                                <div class="mt-2 text-left" *ngIf="submitted && f.email_id.errors">
                                                    <div class="alert-meassge" *ngIf="f.email_id.errors.required">Email is Required</div>
                                                    <div class="alert-meassge" *ngIf="f.email_id.errors.pattern">Email must be a valid email address</div>
                                                </div>     
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control careers-input" id="mobile_number" name="mobile_number" formControlName="mobile_number" placeholder="Mobile Number" maxlength="10" (keypress)="numberOnly($event)" [ngClass]="{ 'is-invalid': submitted && f.mobile_number.errors }"> 
                                                <div class="mt-2 text-left" *ngIf="submitted && f.mobile_number.errors">
                                                    <div class="alert-meassge" *ngIf="f.mobile_number.errors.required">MobileNo is Required</div>
                                                    <div class="alert-meassge" *ngIf="f.mobile_number.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                                </div>         
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control careers-input" id="interested_in" name="interested_in" formControlName="interested_in" placeholder="Position interested in" [ngClass]="{ 'is-invalid': submitted && f.interested_in.errors }">
                                                <div class="mt-2 text-left" *ngIf="submitted && f.interested_in.errors">
                                                    <div class="alert-meassge" *ngIf="f.interested_in.errors.required">Position interested in is Required</div>
                                                </div>   
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control careers-input" id="work_experience" name="work_experience" formControlName="work_experience" placeholder="Total Work Experience" [ngClass]="{ 'is-invalid': submitted && f.work_experience.errors }">
                                                <div class="mt-2 text-left" *ngIf="submitted && f.work_experience.errors">
                                                    <div class="alert-meassge" *ngIf="f.work_experience.errors.required">Total Work Experience is Required</div>
                                                </div>  
                                            </div>
                                            <div class="form-group text-left">
                                                <label class="Resume-label" for="resume">Resume</label>
                                                <input type="file" class="form-control file-chosen-input" id="resume" name="resume" (change)="handleFileSelect($event)">
                                                <input type="text" class="form-control" id="resume_path" name="resume_path" formControlName="resume_path" hidden>
                                                <input type="text" class="form-control" id="filetype" name="filetype" formControlName="filetype" hidden>
                                                <div class="mt-2 text-left">
                                                    <div class="alert-meassge" *ngIf="fileformaterrormesg">Document and Pdf file are Allowed.</div>
                                                </div>   
                                            </div>
                                            <button type="button" class="btn careers-submit-btn btn-lg btn-block" (click)="careerSubmit();">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-12 col-12 my-auto">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <h4 class="txt-open-position">Can’t find the position you’re looking for?</h4>
                                        <p class="txt-our-HR mb-4">Our HR team is here to navigate you to it. Contact us.</p>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
