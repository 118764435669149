import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'gopolicy';
  onDeactivate() {
    // document.body.scrollTop = 0;
    window.scroll(0,0);
  }
  constructor(private router:Router) { }
  ngOnInit(): void {
  }
}
