<section id="smeinsure-intro" class="clearfix">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h4 class="mt-5 intro-page-heading">SME Insurance</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <span class="intro-page-txtlight">Backbone of budding entrepreneurs</span>
            </div>
        </div>
        <div class="row btn-row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <a href="https://pos.gopolicy.com/#/newuser" target="_blank" type="button" class="btn btn-joinus mt-3">Join Us</a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <img class="img-fluid hom-page-img" src="assets/images/SME_Insurance_Banner.png" alt=""
                    style="position: relative;">
            </div>
        </div>
    </div>
</section>
<section id="our-experts" class="clearfix">
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-lg-left text-md-left text-sm-center text-center m-2">
          <img class="img-fluid" src="assets/images/View_Icon.png" alt="">
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 text-lg-left text-md-left text-sm-center text-center my-auto">
            <h5 class="txt-notsure">Looking for a policy plan? Most reliable plans at the lowest rates.</h5>
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                <div class="input-group mb-3">
                  <input type="text" class="form-control callback-input" placeholder="Enter your mobile number" aria-label="Recipient's username" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Get a call back</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<section id="insurance-advisor">
    <div class="container-fluid">
    <h4 class="text-center txt-Frequent-heading mt-5">SME Insurance - Overview</h4>
        <p class="text-center wcg-sub-title mb-4">We offer competitive rates on coverage tailored to fit your needs.</p>
<div class="container py-2 mt-4 mb-4">
  <!-- timeline item 1 -->
  <div class="row no-gutters">
    <div class="col-sm"> <!--spacer--> </div>
    <!-- timeline item 1 center dot -->
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
</g>
<path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
<defs>
<filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
</span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <!-- timeline item 1 event content -->
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-left">
          <h4 class="card-title text-center timeline-mesg-box-heading">Safeguarding investors’ heaven</h4>
          <p class="card-text text-center timeline-mesg-box-para">For a developing country like India, SMEs are a major contributor to the economy. Be it a company or a shop, SMEs are one of the largest platforms to invest.</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
  <!-- timeline item 2 -->
  <div class="row no-gutters">
    <div class="col-sm py-2">
      <div class="card shadow">
        <div class="card-body timeline-mesg-box arrow-right">
          <h4 class="card-title timeline-mesg-box-heading text-center">Avoiding irreparable losses</h4>
          <p class="card-text timeline-mesg-box-para text-center">Apart from entrepreneurial risks, business face other risks like fire, natural calamities, riots, etc., and without insurance, it would prove to be an irreparable loss.</p>
        </div>
      </div>
    </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm"> <!--spacer--> </div>
  </div>
  <!--/row-->
  <!-- timeline item 3 -->
  <div class="row no-gutters">
    <div class="col-sm"> <!--spacer--> </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-left">
          <h4 class="card-title text-center timeline-mesg-box-heading">What claims can be made?</h4>
          <p class="timeline-mesg-box-para text-center">	A company or shop can be placed under this category if it has 1-99 (small) or (100-499) employees. SME insurance covers property, liability and directors’ risks to keep the vision of the companies going.</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
  <!-- timeline item 4 -->

  <!--/row-->
</div>
    </div>
</section>
<section id="our-values">
    <div class="container-fluid">
        <h4 class="txt-Frequent-heading text-center padding-top-bottom-40">Benefits and Features</h4>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-sm-12 col-12 benefit-gid text-center">
                    <div class="grid-top-icon">
                        <a href="" class="benefit-gid-icon"><svg width="35" height="35" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M5.45402 18.8021C5.03189 18.851 4.7281 19.2322 4.77509 19.6555L4.93258 21.0676L9.39399 18.3454L5.45402 18.8021Z" fill="#FF6333"/>
                            <path d="M6.06055 31.1621L6.78351 37.6347C6.80564 37.8389 6.90973 38.0263 7.07 38.1526C7.15936 38.2235 7.26169 38.2737 7.36985 38.2999C7.45851 38.3214 7.54925 38.3275 7.64103 38.3163L10.2412 38.0139L6.06055 31.1621Z" fill="#FF6333"/>
                            <path d="M39.407 33.859L38.5793 26.453L31.1995 30.9559L34.4064 30.5841C34.8282 30.5303 35.215 30.8371 35.2632 31.2623C35.3132 31.6863 35.0087 32.0705 34.585 32.1191L30.5106 32.5925C30.4173 32.6033 30.3265 32.5972 30.2394 32.5761C29.9344 32.5023 29.6914 32.2446 29.6538 31.9143C29.6534 31.9094 29.656 31.9053 29.6556 31.9004L21.8354 36.672L38.7281 34.7124C39.1517 34.6639 39.4555 34.2826 39.407 33.859Z" fill="#FF6333"/>
                            <path d="M42.2105 21.5226L33.1021 6.59499C32.8801 6.23113 32.4039 6.11583 32.04 6.33784L4.05079 23.4159C3.68693 23.6379 3.57163 24.1142 3.79364 24.478L12.902 39.4057C13.012 39.5882 13.1865 39.7083 13.3788 39.7549C13.5711 39.8014 13.7812 39.7744 13.9637 39.6643L41.953 22.5862C42.3172 22.3627 42.4321 21.8879 42.2105 21.5226ZM13.094 23.5507L9.36213 25.8278C9.17961 25.9378 8.96954 25.9649 8.77723 25.9184C8.58492 25.8718 8.41049 25.7517 8.29854 25.5703C8.07653 25.2064 8.19183 24.7302 8.55569 24.5082L12.2876 22.2311C12.6515 22.0091 13.1277 22.1244 13.3497 22.4882C13.5717 22.8521 13.4583 23.3272 13.094 23.5507ZM25.835 27.6452C25.1744 28.0483 24.39 28.1287 23.5863 27.9341C22.2583 27.6126 20.8749 26.5414 19.8915 24.9277C19.172 23.7484 18.7799 22.4512 18.7892 21.2767C18.8008 19.9675 19.2994 18.9464 20.1935 18.3995C21.0876 17.8526 22.2237 17.8764 23.3943 18.4668C24.4431 18.9958 25.4177 19.9362 26.1368 21.117C27.7187 23.7073 27.5871 26.5761 25.835 27.6452ZM37.4462 21.4951L33.7143 23.7722C33.5318 23.8822 33.3217 23.9093 33.1294 23.8628C32.9371 23.8162 32.7627 23.6961 32.6507 23.5147C32.4287 23.1508 32.544 22.6746 32.9079 22.4526L36.6398 20.1755C37.0037 19.9534 37.4799 20.0687 37.7019 20.4326C37.9239 20.7965 37.8105 21.2716 37.4462 21.4951Z" fill="#FF6333"/>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="37.0968" height="37.0968" fill="white" transform="translate(9.33789 0.608398) rotate(13.6099)"/>
                            </clipPath>
                            </defs>
                            </svg></a>
                    </div>
  
                    <h6 class="timeline-mesg-box-heading mt-4">Disaster cover</h6>
                    <p class="timeline-mesg-box-para">Any possible damage caused due to factors like fire, riots, lightning, explosions and other natural calamities are covered under SME insurance.</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-12 benefit-gid text-center">
                    <div class="grid-top-icon">
                        <a href="" class="benefit-gid-icon"><svg width="35" height="35" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M13.1954 23.584L17.5737 40.0005L3.09766 23.584H13.1954Z" fill="#FF6333"/>
                            <path d="M13.279 21.2388H3.09766L9.79011 14.5463C10.0106 14.3266 10.3084 14.2031 10.6195 14.2031H17.2995L13.279 21.2388Z" fill="#FF6333"/>
                            <path d="M24.0214 21.2388H15.9805L20.0009 14.2031L24.0214 21.2388Z" fill="#FF6333"/>
                            <path d="M24.3785 23.584L20.0008 40.0005L15.623 23.584H24.3785Z" fill="#FF6333"/>
                            <path d="M26.7216 21.2388L22.7012 14.2031H29.3811C29.6922 14.2031 29.9901 14.3266 30.2105 14.5463L36.903 21.2388H26.7216Z" fill="#FF6333"/>
                            <path d="M26.8061 23.584H36.9038L22.4277 40.0005L26.8061 23.584Z" fill="#FF6333"/>
                            <path d="M20.0007 6.68635C19.3531 6.68635 18.8281 6.16134 18.8281 5.51375V1.17261C18.8281 0.525015 19.3531 0 20.0007 0C20.6483 0 21.1733 0.525015 21.1733 1.17261V5.51375C21.1733 6.16134 20.6484 6.68635 20.0007 6.68635Z" fill="#FF6333"/>
                            <path d="M8.34354 9.10748L6.17297 5.34795C5.84909 4.78714 6.04125 4.06997 6.60214 3.74618C7.16296 3.42238 7.8802 3.61445 8.20392 4.17535L10.3745 7.93488C10.6984 8.4957 10.5062 9.21286 9.94532 9.53666C9.38684 9.85912 8.66874 9.67088 8.34354 9.10748Z" fill="#FF6333"/>
                            <path d="M30.0553 9.53669C29.4944 9.2129 29.3022 8.49573 29.6261 7.93491L31.7967 4.17538C32.1205 3.61449 32.8375 3.42234 33.3984 3.74621C33.9593 4.07001 34.1515 4.78717 33.8276 5.34799L31.657 9.10752C31.3322 9.67006 30.6144 9.85955 30.0553 9.53669Z" fill="#FF6333"/>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="40" height="40" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg></a>
                    </div>
                    <h6 class="timeline-mesg-box-heading mt-4">Human made Damages</h6>
                    <p class="timeline-mesg-box-para">Apart from this, SME insurance also covers burglary and robbery losses. This not only covers the damage of contents but also any possible damage to the building as a result of forced entry.</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-12 benefit-gid text-center">
                    <div class="grid-top-icon">
                        <a href="" class="benefit-gid-icon"><svg width="35" height="35" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0)">
                            <path d="M23.3022 14.1734C17.0092 14.1734 11.8889 19.2937 11.8889 25.5867C11.8889 31.8797 17.0092 37 23.3022 37C29.5951 37 34.7155 31.8797 34.7155 25.5867C34.7155 19.2937 29.5951 14.1734 23.3022 14.1734ZM20.8494 21.0579C21.7237 21.0579 22.4353 21.7688 22.4353 22.6431C22.4353 23.5181 21.7237 24.2298 20.8494 24.2298C19.9751 24.2298 19.2635 23.5181 19.2635 22.6431C19.2635 21.7688 19.9751 21.0579 20.8494 21.0579ZM25.7549 30.1155C24.8806 30.1155 24.169 29.4046 24.169 28.5303C24.169 27.6553 24.8806 26.9436 25.7549 26.9436C26.6292 26.9436 27.3408 27.6553 27.3408 28.5303C27.3408 29.4046 26.6292 30.1155 25.7549 30.1155ZM27.0894 23.4875L21.203 29.3739C20.734 29.8414 19.9819 29.8394 19.5149 29.3739C19.0487 28.9077 19.0487 28.152 19.5149 27.6858L25.4013 21.7994C25.8675 21.3332 26.6232 21.3332 27.0894 21.7994C27.5557 22.2656 27.5557 23.0213 27.0894 23.4875ZM20.0945 0H12.3364C11.4298 0 10.8544 0.97319 11.2898 1.7672L13.5695 5.92746C11.9461 6.89334 11.4249 8.95997 12.332 10.5629C8.3097 11.9325 5.18111 15.3736 4.34488 19.7308L2.39194 29.9073C1.68581 33.5901 4.5087 37 8.25301 37H13.2241C14.1224 37 14.562 35.908 13.9165 35.2832C6.2668 27.8773 9.8329 15.0253 20.0409 12.484C20.9904 12.2476 21.0926 10.899 20.099 10.5628C21.006 8.96012 20.485 6.89334 18.8614 5.92739L21.1411 1.76712C21.5772 0.971996 20.9998 0 20.0945 0V0ZM17.1106 9.92137H15.3203C14.6348 9.92137 14.1014 9.25671 14.3297 8.53835C14.4647 8.1124 14.8735 7.83266 15.3203 7.83266H17.1106C17.5575 7.83266 17.9663 8.1124 18.1013 8.53835C18.3295 9.25671 17.7962 9.92137 17.1106 9.92137Z" fill="#FF6333"/>
                            </g>
                            <defs>
                            <clipPath id="clip0">
                            <rect width="37" height="37" fill="white"/>
                            </clipPath>
                            </defs>
                            </svg></a>
                    </div>
                    <h6 class="timeline-mesg-box-heading mt-4">Tax Benefits</h6>
                    <p class="timeline-mesg-box-para">There are no tax benefits for SME Insurance</p>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="our-partners">
  <h4 class="text-center txt-Frequent-heading mt-3">Our Partners</h4>
  <p class="text-center wcg-sub-title mb-4">Collaborating with some of your favorite brands</p>
    <div class="container-fluid mb-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Reliance.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Screenshot.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/icici.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Future_Generali.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-3 col-6">
                    <img src="assets/images/united-india-logo.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/hdfc-ergo.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/logo(1)1.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/new-india-assurance.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Screenshot2021.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Shriram.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Care.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/digit.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
            </div>
            <div class="row color tier-4 mt-3">
                <div class="col-lg-4 col-6 text-center">
                    <img src="assets/images/MAGMAHDI.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-4 col-6 text-center">
                    <img src="assets/images/image2.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-4 col-6 text-center">
                    <img src="assets/images/image3.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
            </div>
            <div class="row color tier-5 mt-3">
                <div class="col-lg-8 col-6">
                    <img src="assets/images/90eb84aefe515d5aaa7162b9e931ea67.png" alt=""
                        class="img-fluid img-mix-blend-mode">
                </div>
                <div class="col-lg-3 col-6">
                    <img src="assets/images/Raheja_QBE.png" alt="" class="img-fluid img-mix-blend-mode">
                </div>
            </div>
        </div>
    </div>
</section>
<section id="claim-insurance">
    <div class="container-fluid">
        <div class="row my-5">
            <div class="col-lg-1"></div>
            <div class="col-lg-7 col-md-10 col-12">
                <span class="txt-position">Comprehensive insurance is an optional auto insurance coverage that protects
                    your car against damage not resulting from a collision, as well as from theft.
                </span>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <button class="btn btn-view-more mt-4 mb-3 px-4" data-toggle="modal" data-target="#submitclaim">Claim insurance</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12">
                <img class="img-fluid" src="assets/images/aboutus/Rentalcaservice.png" alt="">
            </div>
        </div>
    </div>
</section>
<section id="why-choose-gopolicy">
    <div class="container-fluid">
      <h4 class="text-center wcg-title mt-3">Why choose go policy?</h4>
      <p class="text-center wcg-sub-title mb-4">We offer competitive rates on coverage tailored to fit your needs.</p>
      <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-12 col-12">
          <div class="box text-center">
            <img class="img-fluid" src="assets/images/customized_plans.png" alt="">
            <h4 class="wcg-box-heading mt-3">Expert Guidance</h4>
            <p class="wcg-box-desc">Let us know your requirements and our experts will immediately provide you with the best available options.</p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-12 col-12">
          <div class="box text-center">
            <img class="img-fluid" src="assets/images/guidance.png" alt="">
            <h4 class="text-center wcg-box-heading mt-3">Prompt Service</h4>
            <p class="wcg-box-desc">We take the path along with our customers leaving no stones unturned. Experience the best quality service with Gopolicy.</p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-12 col-12">
          <div class="box text-center">
            <img class="img-fluid" src="assets/images/value_money_2.png" alt="">
            <h4 class="text-center wcg-box-heading mt-3">Transparent Process</h4>
            <p class="wcg-box-desc">Ever fallen for a scam? Forget the past and move on with us. We promise transparency, security, and safety throughout the process.</p>
          </div>
        </div>
      </div>
  </div>
  </section>
<section id="questions">
    <h4 class="text-center txt-Frequent-heading mt-3">Frequently Asked Questions</h4>
    <p class="text-center wcg-sub-title mb-4">Feel free to ask us mores</p>
    <div class="container-fluid mb-5">
        <div class="container">
            <div id="accordion">
                <div class="card">
                    <div class="card-header pt-3 pb-3" id="headingOne" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        <h5 class="mb-0">
                            <div class="row">
                                <div class="col-lg-9 col-10">
                                  <P class="accordion-heading">Why do I need SME insurance?</P>  
                                </div>
                                <div class="col-lg-3 text-right col-2">
                                    <i class="fas fa-plus"></i>
                                </div>
                            </div>
                        </h5>
                    </div>
  
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                        <div class="card-body">
                            <span class="card-bodydesc">
                                On a broader level, SME insurance covers Business Liability, where your business is responsible for someone’s damage, injury, or loss. It also covers Commercial Property, which accounts for business property, including the building and the contents.
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header collapsed pt-3 pb-3" id="headingTwo" data-toggle="collapse"
                        data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <h5 class="mb-0">
                            <div class="row">
                                <div class="col-lg-9 col-10">
                                    <p class="accordion-heading">What does SME insurance cover?</p>
                                </div>
                                <div class="col-lg-3 text-right col-2">
                                    <i class="fas fa-plus"></i>
                                </div>
                            </div>
                        </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                            <span class="card-bodydesc">
                                On a broader level, SME insurance covers Business Liability, where your business is responsible for someone’s damage, injury, or loss. It also covers Commercial Property, which accounts for business property, including the building and the contents.
                            </span>
  
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header collapsed pt-3 pb-3" id="headingThree" data-toggle="collapse"
                        data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <h5 class="mb-0">
                            <div class="row">
                                <div class="col-lg-9 col-10">
                                    <p class="accordion-heading">What is the business model of the Go Policy?</p>
                                </div>
                                <div class="col-lg-3 text-right col-2">
                                    <i class="fas fa-plus"></i>
                                </div>
                            </div>
                        </h5>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div class="card-body">
                            <span class="card-bodydesc">
                                On a broader level, SME insurance covers Business Liability, where your business is responsible for someone’s damage, injury, or loss. It also covers Commercial Property, which accounts for business property, including the building and the contents.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>