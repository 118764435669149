import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
sub:any;
page:any;
  constructor(private auth:AuthService,private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      if(params.page=="OurTeam"){
        document.getElementById("behind-gopolicy").scrollIntoView();
        this.page = +params['page'] || 0;
      }
      if(params.page=="HowWeDOIt"){
        document.getElementById("how-we-do").scrollIntoView();
        this.page = +params['page'] || 0;
      }
    });
  }


}
