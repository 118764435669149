import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;  

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  callBackForm:FormGroup;
  submitted=false;
  sub:any;
  page:any;
  constructor(private route: ActivatedRoute,private formBuilder:FormBuilder,private auth:AuthService,private router:Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.sub = this.route
    .queryParams
    .subscribe(params => {
      console.log(JSON.stringify(params));
      if(params.page=="Testimonials"){
        document.getElementById("testimonial").scrollIntoView();
        this.page = +params['page'] || 0;
      }
      if(params.page=="FAQ"){
        document.getElementById("faqhome").scrollIntoView();
        this.page = +params['page'] || 0;
      }

    });

    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).parent('.panel').find('.fa-minus').show();
      $(this).parent('.panel').find('.fa-plus').hide();
    })
    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).parent('.panel').find('.fa-minus').hide();
      $(this).parent('.panel').find('.fa-plus').show();
    });
        $(".owl-carousel1").owlCarousel({
          autoplay:true,
          autoplayTimeout:5000,
          loop: true,
          center: true,
          margin: 0,
          responsiveClass: true,
          nav: false,
          navText: [" ", " "],
          responsive: {
            0: {
              items: 1,
              nav: false
            },
            680: {
              items: 2,
              nav: false,
              loop: false
            },
            1000: {
              items: 3,
              nav: true
            }
          }
        });
    this.callBackForm=this.formBuilder.group({
      mobile_no:["", [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
 
  }
  get q() { return this.callBackForm.controls; }
  showSelectedTab(x){
    var otabMotor = document.getElementById("divMotor");
    var otabLife = document.getElementById("divLife");
    var otabHome = document.getElementById("divHome");
    var otabTravel = document.getElementById("divTravel");
    var otabHealth = document.getElementById("divHealth");
    var otabSME = document.getElementById("divSME");
    var otabCorporate = document.getElementById("divCorporate");
    $(otabMotor).attr("style","display:none;");
    $(otabLife).attr("style","display:none;");
    $(otabHome).attr("style","display:none;");
    $(otabTravel).attr("style","display:none;");
    $(otabHealth).attr("style","display:none;");
    $(otabSME).attr("style","display:none;");
    $(otabCorporate).attr("style","display:none;");
     var obtntabMotor = document.getElementById("tabMotor");
     var obtntabLife = document.getElementById("tabLife");
     var obtntabHome = document.getElementById("tabHome");
     var obtntabTravel = document.getElementById("tabTravel");
     var obtntabHealth = document.getElementById("tabHealth");
     var obtntabSME = document.getElementById("tabSME");
     var obtntabCorporate = document.getElementById("tabCorporate");
    $(obtntabMotor).attr("class","nav-item nav-link");
    $(obtntabLife).attr("class","nav-item nav-link");
    $(obtntabHome).attr("class","nav-item nav-link");
    $(obtntabTravel).attr("class","nav-item nav-link");
    $(obtntabHealth).attr("class","nav-item nav-link");
    $(obtntabSME).attr("class","nav-item nav-link");
    $(obtntabCorporate).attr("class","nav-item nav-link");
  
    if(x == "Motor"){
      $(otabMotor).attr("style","display:block;");
      $(obtntabMotor).attr("class","nav-item nav-link btn-active");
  }
  if(x == "Life"){
      $(otabLife).attr("style","display:block;");
      $(obtntabLife).attr("class","nav-item nav-link btn-active");
  }
  if(x == "Home"){
    $(otabHome).attr("style","display:block;");
    $(obtntabHome).attr("class","nav-item nav-link btn-active");
  }
  if(x == "Travel"){
    $(otabTravel).attr("style","display:block;");
    $(obtntabTravel).attr("class","nav-item nav-link btn-active");
  }
  if(x == "Health"){
    $(otabHealth).attr("style","display:block;");
    $(obtntabHealth).attr("class","nav-item nav-link btn-active");
  }
  if(x == "SME"){
    $(otabSME).attr("style","display:block;");
    $(obtntabSME).attr("class","nav-item nav-link btn-active");
  }
  if(x == "Corporate"){
    $(otabCorporate).attr("style","display:block;");
    $(obtntabCorporate).attr("class","nav-item nav-link btn-active");
  }
  }
  callBackSubmit(){
    this.submitted=true;
    this.spinner.show(); 
   if(this.callBackForm.invalid){
    this.spinner.hide();
    return; 
   }else{
    this.auth.callBackFormSave(this.callBackForm.value).subscribe(response =>{
      this.spinner.hide(); 
      if(response.status=="Success"){
        this.callBackForm.reset();
        this.submitted=false;
        Swal.fire('Success!', 'Successfully', 'success');
      }else{
        Swal.fire('Oops...', 'Something went wrong!', 'error');
      }
    console.log(JSON.stringify(response));
    },error =>{
      console.log(error);
    });
   }   
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
