import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/auth/auth.service';
@Component({
  selector: 'app-travelinsurance',
  templateUrl: './travelinsurance.component.html',
  styleUrls: ['./travelinsurance.component.css']
})
export class TravelinsuranceComponent implements OnInit {

  travelcallBackForm:FormGroup;
  submitted=false;
  constructor(private formBuilder:FormBuilder,private auth:AuthService,private router:Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.travelcallBackForm=this.formBuilder.group({
      mobile_no:["", [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }
  get q() { return this.travelcallBackForm.controls; }
  callBackSubmit(){
    this.submitted=true;
    this.spinner.show(); 
    if(this.travelcallBackForm.invalid){
      this.spinner.hide();
      return; 
     }else{
      this.auth.callBackFormSave(this.travelcallBackForm.value).subscribe(response =>{
        this.spinner.hide(); 
        if(response.status=="Success"){
          this.travelcallBackForm.reset();
          this.submitted=false;
          Swal.fire('Success!', 'Successfully', 'success');
        }else{
          this.travelcallBackForm.reset();
          this.submitted=false;
          Swal.fire('Oops...', 'Something went wrong!', 'error');
        }
      console.log(JSON.stringify(response));
      },error =>{
        console.log(error);
      });
     }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
