import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
// baseUrl="http://localhost:8080/";
// baseUrl="https://pot.fapremium.net/webDesign/api/";
 baseUrl="https://integration.gopolicy.com/webDesign/";
  constructor(private http:HttpClient) { }
  quereyFormSave(input):Observable<any>{
    const headers={
      "Content-type":"application/json"
    };
  return this.http.post(this.baseUrl+'sendQuerey',input,{headers});
  }
  claimFormSave(input):Observable<any>{
    const headers={
    "Content-type":"application/json"
    };
    return this.http.post(this.baseUrl+"sendClaim",input,{headers})
  }
  careerFormSave(input):Observable<any>{
    const headers={
    "Content-type":"application/json"
    };
    return this.http.post(this.baseUrl+"sendCareers",input,{headers})
  }
  callBackFormSave(input):Observable<any>{
    const headers={
      "Content-type":"application/json"
    };
    return this.http.post(this.baseUrl+"sendCallBack",input,{headers});
  }
}
