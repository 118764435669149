<section id="smeinsure-intro" class="clearfix">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h4 class="mt-5 intro-page-heading">Marine/Transit Insurance</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <span class="intro-page-txtlight">Think beyond the sink</span>
            </div>
        </div>
        <div class="row btn-row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <a href="https://pos.gopolicy.com/#/newuser" target="_blank" type="button" class="btn btn-joinus mt-5">Join Us</a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <img class="img-fluid hom-page-img" src="assets/images/Marine_Banner.png" alt=""
                    style="position: relative;">
            </div>
        </div>
    </div>
</section>
<section id="our-experts" class="clearfix">
    <div class="container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 text-lg-left text-md-left text-sm-center text-center m-2">
          <img class="img-fluid" src="assets/images/View_Icon.png" alt="">
          </div>
          <div class="col-lg-7 col-md-7 col-sm-12 col-12 text-lg-left text-md-left text-sm-center text-center my-auto">
            <h5 class="txt-notsure">Looking for a policy plan? Most reliable plans at the lowest rates.</h5>
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                <form action="" [formGroup]="transitcallBackForm">
                    <div class="input-group mb-1">
                      <input type="text" class="form-control callback-input" placeholder="Enter your mobile number"
                        aria-label="Recipient's username" aria-describedby="basic-addon2" formControlName="mobile_no" name="mobile_no" maxlength="10" (keypress)="numberOnly($event)">
                      <div class="input-group-append">
                        <button type="button" class="input-group-text" (click)="callBackSubmit();">Get a call back</button>
                      </div>
                    </div>
                    <div class="text-left ml-2" *ngIf="submitted && q.mobile_no.errors">
                        <div class="alert-meassge ml-2" *ngIf="q.mobile_no.errors.required">Mobile Number is Required.</div>
                        <div class="alert-meassge ml-2" *ngIf="q.mobile_no.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<section id="insurance-advisor">
    <div class="container-fluid">
      <div class="section-title pt-70">
        <h2>Transit Insurance - Overview</h2>
        <p class="mb-4">We offer competitive rates on coverage tailored to fit your needs.</p>
      </div>
<div class="container py-2 mt-4 mb-4">
  <!-- timeline item 1 -->
  <div class="row no-gutters">
    <div class="col-sm"> <!--spacer--> </div>
    <!-- timeline item 1 center dot -->
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
</g>
<path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
<defs>
<filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="4"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
</span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <!-- timeline item 1 event content -->
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-left">
          <h4 class="card-title text-center timeline-mesg-box-heading">Wide range of risk covered</h4>
        <p class="card-text text-center timeline-mesg-box-para">Non-delivery of consignment due to hijacking or mysterious
            disappearance of the vehicle will also be covered.</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
  <!-- timeline item 2 -->
  <div class="row no-gutters">
    <div class="col-sm py-2">
      <div class="card shadow">
        <div class="card-body timeline-mesg-box arrow-right">
          <h4 class="card-title timeline-mesg-box-heading text-center">Damage due to third party</h4>
        <p class="card-text timeline-mesg-box-para text-center">Damages caused due to leakage, heat or explosion of the cargo
            will also be covered. This may occur due to insufficient space or improper handling of the cargo.</p>
        </div>
      </div>
    </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm"> <!--spacer--> </div>
  </div>
  <!--/row-->
  <!-- timeline item 3 -->
  <div class="row no-gutters">
    <div class="col-sm"> <!--spacer--> </div>
    <div class="col-sm-1 text-center flex-column d-none d-sm-flex">
      <div class="row h-50">
        <div class="col border-dashed-right">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
      <h5 class="m-2">
        <span class="badge badge-pill">&nbsp;<svg width="55" height="55" viewBox="0 0 67 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_d)">
          <circle cx="33.1665" cy="33" r="25" fill="#FF6333"/>
          </g>
          <path d="M44.0235 25.3614C43.5846 24.9225 42.8731 24.9225 42.4341 25.3614L28.9699 38.8257L23.7945 33.6503C23.3556 33.2114 22.6441 33.2115 22.2052 33.6503C21.7662 34.0892 21.7662 34.8007 22.2052 35.2396L28.1752 41.2096C28.614 41.6485 29.326 41.6482 29.7646 41.2096L44.0235 26.9508C44.4624 26.5119 44.4623 25.8003 44.0235 25.3614Z" fill="white"/>
          <defs>
          <filter id="filter0_d" x="0.166504" y="0" width="66" height="66" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
          </defs>
          </svg>
          </span>
      </h5>
      <div class="row h-50">
        <div class="col">&nbsp;</div>
        <div class="col">&nbsp;</div>
      </div>
    </div>
    <div class="col-sm py-2">
      <div class="card">
        <div class="card-body timeline-mesg-box arrow-left">
          <h4 class="card-title text-center timeline-mesg-box-heading">Flexible plans to claim losses</h4>
        <p class="timeline-mesg-box-para text-center">Losses can be covered anywhere in India, across multiple dispatch
            locations of the client.</p>
        </div>
      </div>
    </div>
  </div>
  <!--/row-->
  <!-- timeline item 4 -->

  <!--/row-->
</div>
    </div>
</section>
<section id="our-values" class="pb-60">
    <div class="container-fluid">
      <div class="section-title pt-70">
        <h2 class="mb-5">Benefits and Features</h2>
      </div>
        <div class="container-fluid">
          <section id="counts" class="counts">
              <div class="row equal justify-content-center">
                <div class="col-lg-3 col-md-6 d-flex">
                  <div class="count-box">
                    <i class="">
                      <svg width="35" height="35" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                   <g>
                     <g>
                       <path d="M378.606,64.705c-30.957-22.233-68.609-35.622-107.397-38.415V15.208C271.208,6.809,264.399,0,256,0
                         c-8.399,0-15.208,6.809-15.208,15.208V26.29c-38.79,2.793-76.443,16.181-107.4,38.415c-34.005,24.422-54.305,56.467-54.305,85.72
                         c0,5.714,3.203,10.946,8.291,13.543c5.088,2.599,11.205,2.127,15.833-1.224c9.558-6.917,21.646-10.727,34.035-10.727
                         c19.175,0,37.053,9.13,46.655,23.826c2.808,4.298,7.597,6.889,12.731,6.889c0.003,0,0.006,0,0.009,0
                         c5.138-0.003,9.927-2.599,12.732-6.905c9.57-14.686,27.437-23.81,46.624-23.81c19.191,0,37.058,9.124,46.628,23.81
                         c2.805,4.305,7.594,6.902,12.732,6.905c0.003,0,0.006,0,0.009,0c5.134,0,9.923-2.591,12.731-6.889
                         c9.602-14.696,27.48-23.826,46.655-23.826c12.389,0,24.478,3.81,34.035,10.727c4.628,3.351,10.744,3.823,15.833,1.224
                         c5.089-2.599,8.291-7.83,8.291-13.543C432.911,121.173,412.61,89.127,378.606,64.705z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M209.472,390.742C212.58,465.254,238.091,512,255.999,512c17.91,0,43.42-46.746,46.528-121.258H209.472z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M198.048,488.541c-10.952-26.769-17.569-61.048-19.005-97.799h-58.657c5.603,50.293,38.643,92.416,83.771,111.018
                         C202.012,497.642,199.97,493.24,198.048,488.541z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M332.957,390.742c-1.436,36.751-8.053,71.03-19.005,97.799c-1.922,4.698-3.964,9.1-6.11,13.219
                         c45.128-18.602,78.169-60.725,83.771-111.018H332.957z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M120.386,360.327h58.657c1.436-36.751,8.053-71.03,19.005-97.8c1.922-4.698,3.964-9.099,6.11-13.218
                         C159.03,267.913,125.989,310.035,120.386,360.327z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M255.996,182.433c-5.621,0-11.003,1.575-15.205,4.266v22.653c5.01-0.454,10.081-0.7,15.208-0.7
                         c5.127,0,10.197,0.246,15.208,0.701v-22.651C267.005,184.009,261.62,182.433,255.996,182.433z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M307.842,249.309c2.145,4.118,4.187,8.521,6.11,13.218c10.952,26.77,17.569,61.049,19.005,97.8h58.657
                         C386.011,310.034,352.97,267.912,307.842,249.309z"/>
                     </g>
                   </g>
                   <g>
                     <g>
                       <path d="M255.999,239.07c-17.91,0-43.42,46.746-46.528,121.258h93.055C299.42,285.816,273.909,239.07,255.999,239.07z"/>
                     </g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   </svg></i>
                    <h6 class="timeline-mesg-box-heading mt-4">Global Standard Coverage</h6>
                    <p class="timeline-mesg-box-para">The terms of coverage under transit insurance policies are universally accepted. As a
                      consequence, even though you're shipping your goods abroad, you'll be able to fulfil the country's coverage
                      criteria.</p>
                  </div>
                </div>
          
                <div class="col-lg-3 col-md-6 mt-5 d-flex mt-md-0">
                  <div class="count-box">
                    <i class="">
                        <svg xmlns="http://www.w3.org/2000/svg" height="35" version="1.1" viewBox="0 -15 511.9992 511" width="35">
                          <g id="surface1">
                          <path d="M 256 0.5 C 214.648438 0.5 181 35.148438 181 76.5 C 181 117.851562 214.648438 151.5 256 151.5 C 297.351562 151.5 331 117.851562 331 76.5 C 331 35.148438 297.351562 0.5 256 0.5 Z M 256 0.5 " style=" stroke:none;fill-rule:nonzero;fill:#FF6333;fill-opacity:1;" />
                          <path d="M 361 271.5 C 394.089844 271.5 421 244.589844 421 211.5 C 421 178.410156 394.089844 151.5 361 151.5 C 327.910156 151.5 301 178.410156 301 211.5 C 301 244.589844 327.910156 271.5 361 271.5 Z M 361 271.5 " style=" stroke:none;fill-rule:nonzero;fill:#FF6333;fill-opacity:1;" />
                          <path d="M 15 451.5 L 30 451.5 C 46.570312 451.5 60 438.066406 60 421.5 L 60 241.5 C 60 224.929688 46.570312 211.5 30 211.5 L 15 211.5 C 6.714844 211.5 0 218.214844 0 226.5 L 0 436.5 C 0 444.785156 6.714844 451.5 15 451.5 Z M 15 451.5 " style=" stroke:none;fill-rule:nonzero;fill:#FF6333;fill-opacity:1;" />
                          <path d="M 90 241.5 L 90 421.5 C 90 424.523438 89.539062 427.421875 89.109375 430.324219 L 176.632812 473.589844 C 186.988281 478.761719 198.59375 481.5 210.164062 481.5 C 214.765625 481.5 345.660156 481.5 345.660156 481.5 C 356.808594 481.5 367.707031 478.410156 377.199219 472.550781 L 500.015625 385.722656 C 513.242188 375.761719 515.9375 356.921875 506.007812 343.695312 C 497 331.6875 479.542969 326.484375 464.007812 337.707031 L 371.679688 389.785156 C 369.527344 390.914062 367.140625 391.5 364.707031 391.5 C 359.300781 391.5 186.558594 391.5 193 391.5 C 184.707031 391.5 178 384.792969 178 376.5 C 178 368.210938 184.707031 361.5 193 361.5 L 331 361.5 C 347.570312 361.5 361 348.066406 361 331.5 C 361 314.929688 347.570312 301.5 331 301.5 L 260.539062 301.5 L 189.4375 254.097656 C 177.089844 245.851562 162.703125 241.5 147.835938 241.5 Z M 90 241.5 " style=" stroke:none;fill-rule:nonzero;fill:#FF6333;fill-opacity:1;" />
                          </g>
                          </svg></i>
                    <h6 class="timeline-mesg-box-heading mt-4">Financial support to Business</h6>
                    <p class="timeline-mesg-box-para">Transit insurance coverage provides financial support to businesses which might face
                      considerable losses if their goods are damaged during transit. The policy, therefore, helps keep the business
                      finances stable even after a loss.</p>
                  </div>
                </div>
          
                <div class="col-lg-3 col-md-6 mt-5 d-flex mt-lg-0">
                  <div class="count-box">
                    <i class="">
                      <svg width="35" height="35" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 440.344 440.344" style="enable-background:new 0 0 440.344 440.344;" xml:space="preserve">
                   <g>
                     <path d="M416.43,188.455c-0.676-0.876-1.328-1.722-1.95-2.542c-7.762-10.228-14.037-21.74-19.573-31.897
                       c-5.428-9.959-10.555-19.366-16.153-25.871c-12.489-14.513-24.24-21.567-35.925-21.567l-57.701,0
                       c-0.055,0.001-5.567,0.068-12.201,0.068h-9.409c-6.725,0-12.59,4.566-14.262,11.104l-0.078,0.305v127.401l0.014,0.262
                       c0.007,0.077,0.15,1.902-1.289,3.472c-1.587,1.734-4.634,2.65-8.812,2.65H14.345C6.435,251.839,0,257.893,0,265.334v46.388
                       c0,7.441,6.435,13.495,14.345,13.495h49.36c2.586,29.549,27.453,52.81,57.667,52.81c30.214,0,55.083-23.261,57.668-52.81h82.61
                       c2.586,29.549,27.453,52.81,57.667,52.81c30.214,0,55.083-23.261,57.668-52.81h37.545c14.233,0,25.813-11.579,25.813-25.811v-44.29
                       C440.344,219.47,425.953,200.805,416.43,188.455z M340.907,320.132c0,11.901-9.685,21.583-21.59,21.583
                       c-11.901,0-21.583-9.682-21.583-21.583c0-11.901,9.682-21.584,21.583-21.584C331.222,298.548,340.907,308.23,340.907,320.132z
                        M390.551,207.76c-0.451,0.745-1.739,1.066-3.695,0.941l-99.197-0.005v-80.914h42.886c11.539,0,19.716,5.023,28.224,17.337
                       c5.658,8.19,20.639,33.977,21.403,35.293c0.532,1.027,1.079,2.071,1.631,3.125C386.125,191.798,392.658,204.279,390.551,207.76z
                        M121.372,298.548c11.905,0,21.59,9.683,21.59,21.584c0,11.901-9.685,21.583-21.59,21.583c-11.901,0-21.583-9.682-21.583-21.583
                       C99.789,308.23,109.471,298.548,121.372,298.548z"/>
                     <path d="M30.234,231.317h68c6.893,0,12.5-5.607,12.5-12.5v-50c0-6.893-5.607-12.5-12.5-12.5h-68c-6.893,0-12.5,5.607-12.5,12.5v50
                       C17.734,225.71,23.342,231.317,30.234,231.317z"/>
                     <path d="M143.234,231.317h68c6.893,0,12.5-5.607,12.5-12.5v-50c0-6.893-5.607-12.5-12.5-12.5h-68c-6.893,0-12.5,5.607-12.5,12.5v50
                       C130.734,225.71,136.342,231.317,143.234,231.317z"/>
                     <path d="M30.234,137.317h68c6.893,0,12.5-5.607,12.5-12.5v-50c0-6.893-5.607-12.5-12.5-12.5h-68c-6.893,0-12.5,5.607-12.5,12.5v50
                       C17.734,131.71,23.342,137.317,30.234,137.317z"/>
                     <path d="M143.234,137.317h68c6.893,0,12.5-5.607,12.5-12.5v-50c0-6.893-5.607-12.5-12.5-12.5h-68c-6.893,0-12.5,5.607-12.5,12.5v50
                       C130.734,131.71,136.342,137.317,143.234,137.317z"/>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   <g>
                   </g>
                   </svg>
                   </i>
                    <h6 class="timeline-mesg-box-heading mt-4">Damage during transit</h6>
                    <p class="timeline-mesg-box-para">The insurance policy offers coverage between all risks of damage or loss of goods/ or
                      death of the livestock and the majority of selected perils that you can select according to your business line.</p>
                  </div>
                </div>
              </div>
          </section>
        </div>
    </div>
</section>
<section style="background-color:#ffff;">
  <div class="section-title pt-70">
    <h2>Our Partners</h2>
    <p class="mb-4">Collaborating with some of your favorite brands</p>
  </div>
  <div class="container-fluid pb-5 pt-4">
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-9 col-12">
        <div class="row">
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/90eb84aefe515d5aaa7162b9e931ea67.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Care.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/digit.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Edelweiss_General.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Screenshot.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Screenshot2021.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/hdfc-ergo.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/icici.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/icici_prudential_life.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/kotak.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/MAGMAHDI.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/new-india-assurance.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
        </div>
        <div class="row color mt-3">
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Raheja_QBE.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Reliance.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/image2.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/Shriram.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
        </div>
        <div class="row color mt-3">
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/image3.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/tata_aia.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/logo(1)1.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
          <div class="col-lg-3 col-6 text-center my-auto">
            <img src="assets/images/united-india-logo.png" alt="" class="img-fluid img-mix-blend-mode">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="claim-insurance">
    <div class="container-fluid">
        <div class="row my-5">
            <div class="col-lg-1"></div>
            <div class="col-lg-7 col-md-10 col-12">
                <span class="txt-position">We help you provide the information you need before you get in touch with your insurance
                    company and what you can expect from the claim process.</span>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <button class="btn btn-claim mt-4 mb-3 px-4" data-toggle="modal" data-target="#submitclaim">Claim Assist</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-12 text-center">
                <img class="img-fluid" src="assets/images/aboutus/Rentalcaservice.png" alt="">
            </div>
        </div>
    </div>
</section>
<section class="services">
    <div class="container-fluid">
      <div class="section-title pt-70">
        <h2>Why Choose Gopolicy?</h2>
        <p class="mb-4">We offer comprehensive coverage and comparison tailored to fit your needs.</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-11">
          <div class="row">
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div class="icon-box">
                <div class="icon"><svg width="209" height="207" viewBox="0 0 209 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M104.081 0.266528C136.24 -2.2394 167.796 13.1119 187.759 38.4517C207.586 63.6188 214.122 97.5232 204.865 128.197C196.275 156.661 169.68 172.91 143.139 186.305C115.71 200.148 85.5877 214.266 57.0926 202.776C26.8203 190.568 5.19679 161.133 0.576891 128.818C-3.68275 99.0221 16.4237 73.7749 35.299 50.332C54.0105 27.0924 74.3374 2.58429 104.081 0.266528Z" fill="#FFF5F0"/>
                  <path d="M88.668 171.245C88.668 171.245 88.2522 177.898 87.0048 177.898C86.8662 177.898 80.4906 177.898 80.4906 177.898H72.4517C72.4517 177.898 72.4517 175.957 74.8079 175.542C77.1641 175.126 81.1836 173.463 82.5696 170.413C84.0942 167.087 88.668 171.245 88.668 171.245Z" fill="url(#paint0_linear)"/>
                  <path d="M91.5789 93.9053C91.5789 93.9053 88.1139 94.8755 85.7577 94.1825C83.4015 93.4895 79.6592 94.5983 79.1048 95.4299C78.5504 96.2615 78.689 96.5387 79.7978 96.8159C80.9066 97.0931 84.7875 98.7563 87.4209 97.7861C90.0543 96.8159 93.3807 96.8159 93.3807 96.8159L91.5789 93.9053Z" fill="#E49963"/>
                  <path d="M122.071 63.2746C122.071 63.2746 118.052 91.4107 111.538 93.9055C108.35 95.1529 94.0739 97.925 89.7773 98.2022C89.7773 98.2022 88.8071 97.3706 88.9457 93.9055C88.9457 93.9055 103.915 88.3615 105.994 85.4508C109.181 81.0156 104.053 67.2941 107.934 63.136C112.092 59.1166 118.745 57.3148 122.071 63.2746Z" fill="url(#paint1_linear)"/>
                  <path d="M121.517 171.661V165.562H131.219C131.496 165.562 131.635 165.285 131.635 165.147V164.176C131.635 163.899 131.358 163.761 131.219 163.761H121.517V128.833C121.517 127.863 120.685 127.031 119.715 127.031H117.775C116.804 127.031 115.973 127.863 115.973 128.833V163.761H106.271C105.994 163.761 105.855 164.038 105.855 164.176V165.147C105.855 165.424 106.132 165.562 106.271 165.562H115.973V171.661C109.043 172.215 103.36 174.71 101.835 177.898H135.654C134.13 174.71 128.586 172.215 121.517 171.661Z" fill="#C4C4C4"/>
                  <path d="M120.685 122.596C119.576 124.537 118.19 126.338 116.804 128.14C112.507 133.546 107.933 137.981 107.102 142.139C106.963 142.971 106.686 143.802 106.409 144.772C106.132 145.743 105.716 146.713 105.161 147.822C103.36 151.703 100.726 156.138 97.9542 160.157C93.2417 167.087 88.9451 172.77 88.9451 172.77L80.9062 172.493C81.1835 171.661 84.7871 162.098 89.3609 150.455C91.3013 145.743 93.2417 140.753 95.1822 135.902C95.4594 135.209 95.7365 134.655 96.0137 133.962C96.5681 132.437 97.2612 131.051 97.8156 129.665C102.251 118.715 105.716 109.984 105.716 109.984C105.716 109.984 123.318 103.331 125.12 107.627C126.645 111.092 125.397 114.557 120.685 122.596Z" fill="url(#paint2_linear)"/>
                  <path d="M107.241 152.257C107.241 152.257 112.785 155.86 112.231 156.969C112.092 157.108 109.043 162.652 109.043 162.652L105.024 169.721C105.024 169.721 103.36 168.75 104.192 166.394C105.024 164.177 105.439 159.88 103.499 157.108C101.42 154.336 107.241 152.257 107.241 152.257Z" fill="url(#paint3_linear)"/>
                  <path d="M99.7566 106.657C99.7566 106.657 77.7189 120.378 75.2241 129.665C72.7293 138.951 104.608 158.632 104.608 158.632C104.608 158.632 108.073 155.583 108.766 153.227C108.766 153.227 94.3511 136.04 91.3019 132.159C89.3615 129.803 119.577 123.982 123.319 118.161C127.061 112.478 125.675 107.627 125.675 107.627L99.7566 106.657Z" fill="url(#paint4_linear)"/>
                  <path d="M141.337 124.398H96.153C95.1828 124.398 94.4897 125.091 94.4897 126.061V135.07C94.4897 136.04 95.1828 136.734 96.153 136.734H141.337C142.307 136.734 143 136.04 143 135.07V126.061C143 125.091 142.307 124.398 141.337 124.398Z" fill="#3F3A37"/>
                  <path d="M110.013 58.5621C107.795 59.9481 106.687 61.6113 106.687 61.6113C111.953 62.9973 120.685 58.1463 120.685 58.1463C120.685 58.1463 117.22 55.9286 115.973 50.8004C115.973 50.5232 115.834 50.246 115.834 49.9688C115.696 49.1372 115.557 48.167 115.557 47.1968L113.062 49.4144L109.043 53.018C109.043 53.018 109.181 54.5426 109.597 57.0375C109.736 57.5919 109.874 58.0077 110.013 58.5621Z" fill="#E49963"/>
                  <path d="M126.229 83.5107C125.536 93.6286 126.784 109.152 125.952 111.924C125.259 114.28 116.527 112.894 113.062 112.063C111.815 111.785 110.29 111.37 108.766 110.954C104.469 109.568 99.618 107.627 99.2021 106.241V106.103C99.2021 105.964 99.2021 105.687 99.2021 105.548C99.3407 104.855 100.034 104.024 100.034 102.222C100.172 100.143 100.172 97.7866 100.172 95.2918C100.172 95.0146 100.172 94.7374 100.172 94.4602V94.3216V94.183C100.311 91.2723 100.45 88.3617 100.588 85.4511C100.727 83.6493 100.865 81.7088 101.004 80.0456C101.42 75.4718 101.836 71.4523 102.529 68.8189C103.637 64.6609 106.132 61.8889 108.35 59.9484L110.013 58.5624C111.815 58.9782 113.894 58.701 115.834 57.7308C117.913 56.7606 118.606 56.0676 119.022 56.3448C123.596 59.6712 127.061 71.0365 126.229 83.5107Z" fill="url(#paint5_linear)"/>
                  <path d="M108.766 110.815C104.469 109.429 99.618 107.488 99.2021 106.102V105.964C99.2021 105.825 99.2021 105.548 99.2021 105.409C99.3407 104.716 100.034 103.885 100.034 102.083C100.172 100.004 100.172 97.6478 100.172 95.1529C100.172 94.8757 100.172 94.5986 100.172 94.3213V94.1827V94.0441L116.666 86.5597C116.666 86.4211 117.636 99.7268 108.766 110.815Z" fill="url(#paint6_linear)"/>
                  <path d="M109.043 53.0181C109.043 53.0181 109.182 54.5427 109.597 57.0375C113.478 55.7901 115.28 52.1865 115.973 50.6619C116.112 50.2461 116.25 49.9689 116.25 49.9689C116.25 49.9689 116.112 49.9689 115.834 49.8303C115.28 49.6917 114.171 49.5531 113.062 49.2759L109.043 53.0181Z" fill="#D68D5D"/>
                  <path d="M107.518 53.0183C107.518 53.0183 116.25 53.9885 116.666 48.583C117.22 43.1776 118.606 39.7125 113.201 38.8809C107.657 38.0493 106.409 39.9897 105.855 41.7916C105.162 43.3162 105.162 52.6025 107.518 53.0183Z" fill="#E49963"/>
                  <path d="M116.665 48.8601C116.665 48.8601 116.111 41.6529 108.211 40.9599C102.805 40.4055 105.854 35.8316 106.409 35C106.409 35 110.567 35.5544 114.725 36.2474C123.595 37.772 117.497 47.7513 116.665 48.8601Z" fill="url(#paint7_linear)"/>
                  <path d="M68.2749 81.1668L67.4839 81.4238L73.9509 101.329L74.7419 101.072L68.2749 81.1668Z" fill="black"/>
                  <path d="M95.7492 99.5045L73.8545 99.9722L73.8811 101.219L95.7758 100.752L95.7492 99.5045Z" fill="#4A75CB"/>
                  <path d="M93.3808 98.2021C93.3808 98.2021 89.9158 99.4496 87.6981 99.0338C85.3419 98.618 81.7384 100.143 81.1839 100.974C80.6295 101.806 80.9067 102.083 82.0155 102.36C83.1243 102.499 87.2824 103.746 89.6386 102.499C92.1334 101.251 95.4599 100.836 95.4599 100.836L93.3808 98.2021Z" fill="#E49963"/>
                  <path d="M120.27 64.5219C120.27 64.5219 119.438 92.9352 113.201 96.123C110.152 97.6476 96.2916 102.083 92.1336 102.776C92.1336 102.776 91.0248 102.083 90.8862 98.6178C90.8862 98.6178 105.162 91.5492 106.964 88.3613C109.597 83.6489 102.945 70.4818 106.548 65.9079C109.875 61.3341 116.389 58.8393 120.27 64.5219Z" fill="url(#paint8_linear)"/>
                  <defs>
                  <linearGradient id="paint0_linear" x1="72.4372" y1="173.54" x2="88.7121" y2="173.54" gradientUnits="userSpaceOnUse">
                  <stop offset="5.5409e-07"/>
                  <stop offset="1" stop-color="#3F1700"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear" x1="88.9893" y1="78.8628" x2="122.052" y2="78.8628" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear" x1="80.9342" y1="139.378" x2="125.768" y2="139.378" gradientUnits="userSpaceOnUse">
                  <stop offset="4.36879e-07" stop-color="#1A2745"/>
                  <stop offset="1" stop-color="#808797"/>
                  </linearGradient>
                  <linearGradient id="paint3_linear" x1="103.1" y1="161.047" x2="112.383" y2="161.047" gradientUnits="userSpaceOnUse">
                  <stop offset="5.5409e-07"/>
                  <stop offset="1" stop-color="#3F1700"/>
                  </linearGradient>
                  <linearGradient id="paint4_linear" x1="75.0822" y1="132.657" x2="125.802" y2="132.657" gradientUnits="userSpaceOnUse">
                  <stop offset="4.36879e-07" stop-color="#1A2745"/>
                  <stop offset="1" stop-color="#808797"/>
                  </linearGradient>
                  <linearGradient id="paint5_linear" x1="99.026" y1="84.5109" x2="126.355" y2="84.5109" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  <linearGradient id="paint6_linear" x1="99.0814" y1="98.6579" x2="116.663" y2="98.6579" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  <linearGradient id="paint7_linear" x1="105" y1="41.9891" x2="119.493" y2="41.9891" gradientUnits="userSpaceOnUse">
                  <stop offset="5.5409e-07"/>
                  <stop offset="1" stop-color="#3F1700"/>
                  </linearGradient>
                  <linearGradient id="paint8_linear" x1="90.8708" y1="82.0124" x2="120.307" y2="82.0124" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  </defs>
                  </svg>
                  </div>
                <h4><a href="">Expert Guidance</a></h4>
                <p>Let us know your requirements and our experts will immediately provide you with the best available options.</p>
              </div>
            </div>
  
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
              <div class="icon-box">
                <div class="icon"><svg width="209" height="207" viewBox="0 0 209 207" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M104.081 0.266528C136.24 -2.2394 167.796 13.1119 187.759 38.4517C207.586 63.6188 214.122 97.5232 204.865 128.197C196.275 156.661 169.68 172.91 143.139 186.305C115.71 200.148 85.5877 214.266 57.0926 202.776C26.8203 190.568 5.19679 161.133 0.576891 128.818C-3.68275 99.0221 16.4237 73.7749 35.299 50.332C54.0105 27.0924 74.3374 2.58429 104.081 0.266528Z" fill="#FFF5F0"/>
                  <path d="M113.776 121.275C110.739 126.647 106.847 128.671 106.847 128.671H90.8086C90.8086 128.671 116.111 117.227 113.776 121.275Z" fill="url(#paint0_linear)"/>
                  <path d="M101.474 162.539C101.085 163.006 101.007 163.551 101.241 164.096C101.63 165.186 102.876 166.353 104.355 167.521C104.511 167.677 104.744 167.833 104.822 167.911L104.9 167.988L104.978 168.066C106.379 169.312 105.834 169.623 108.326 172.193C111.128 175.073 113.075 175.151 113.62 174.917C114.087 174.684 113.153 173.127 112.063 171.414C111.985 171.336 111.985 171.258 111.907 171.18C111.751 170.947 111.596 170.713 111.518 170.48C110.272 168.378 110.583 167.366 110.895 164.251C111.128 161.682 114.865 150.082 114.865 150.082L109.493 149.147C109.493 149.147 107.858 158.412 106.068 159.814C105.134 160.592 104.044 160.592 103.109 161.137C103.031 161.215 102.954 161.215 102.954 161.293C102.409 161.682 101.864 162.071 101.474 162.539Z" fill="#FFB27D"/>
                  <path d="M101.474 162.538C101.085 163.005 101.007 163.55 101.241 164.095C101.708 165.185 102.954 166.353 104.355 167.521C104.511 167.677 104.666 167.833 104.822 167.91L104.9 167.988L104.978 168.066C106.379 169.312 105.834 169.623 108.326 172.192C111.128 175.073 113.075 175.151 113.62 174.917C114.087 174.684 113.153 173.127 112.063 171.414C111.518 171.18 108.948 170.168 107.08 167.755C105.367 165.575 104.277 162.85 103.109 161.215C103.031 161.293 102.954 161.293 102.954 161.371C102.409 161.682 101.864 162.071 101.474 162.538Z" fill="#233862"/>
                  <path d="M144.761 159.191C145.072 160.826 147.485 160.592 150.133 159.891C152.78 159.269 152.157 160.047 155.894 159.58C159.631 159.191 160.799 157.789 160.877 157.166C160.954 156.699 159.086 156.466 157.14 156.232C156.906 156.232 156.672 156.154 156.361 156.154C154.103 155.843 152.468 154.442 150.444 153.196C148.498 152.028 145.617 147.435 145.617 147.435L140.479 147.824C140.479 147.824 143.203 151.95 144.216 153.741C144.838 154.831 144.761 156.154 144.761 157.244C144.683 158.023 144.683 158.646 144.761 159.191Z" fill="#FFB27D"/>
                  <path d="M115.176 119.562C114.164 120.808 113.23 122.988 112.373 125.635C109.103 136.068 107.391 154.286 107.391 154.286C107.391 154.286 109.103 155.454 110.661 155.843C112.296 156.31 114.008 156.154 114.008 156.154L120.315 139.182L125.142 126.18C125.064 126.18 120.237 113.568 115.176 119.562Z" fill="url(#paint1_linear)"/>
                  <path d="M150.134 159.969C152.781 159.346 152.158 160.124 155.895 159.657C159.632 159.268 160.8 157.867 160.878 157.244C160.956 156.777 159.087 156.543 157.141 156.31C157.141 156.31 154.883 157.945 151.769 158.022C149.2 158.1 146.553 157.322 144.762 157.4C144.684 157.945 144.684 158.567 144.84 159.19C145.151 160.825 147.487 160.592 150.134 159.969Z" fill="#233862"/>
                  <path d="M120.16 105.627C116.968 104.614 108.014 105.938 98.9053 107.651L97.0368 104.537L77.4951 102.979C77.4951 102.979 71.0332 117.305 74.6145 126.414C75.1595 127.737 76.4052 128.282 78.1958 128.36C78.1958 128.36 89.8741 128.672 93.6112 128.672C103.343 128.672 114.165 121.976 115.566 121.743C117.902 121.275 118.758 125.869 121.094 130.696C123.43 135.523 142.66 152.106 142.66 152.106C142.66 152.106 145.618 152.106 146.864 151.639C148.188 151.172 148.655 150.471 148.655 150.471C148.655 150.471 127.245 107.884 120.16 105.627Z" fill="url(#paint2_linear)"/>
                  <path d="M94.9332 47.1569C93.454 46.3005 92.0527 46.1448 91.1963 46.2227C90.5734 46.2227 89.9506 46.1448 89.3277 46.067C81.9315 45.0549 77.4938 55.7989 77.1823 59.0688C76.7152 65.0636 73.5231 65.9979 69.0075 69.7349C60.0542 77.2868 62.7013 86.0845 67.6061 89.3544C70.5646 91.3008 75.7031 89.3544 75.7031 89.3544C75.7809 89.4322 89.8727 81.491 96.8796 77.5983C107.312 76.119 99.1374 49.6483 94.9332 47.1569Z" fill="url(#paint3_linear)"/>
                  <path d="M91.041 74.3282C91.041 74.3282 87.6153 75.496 85.2797 74.4839C83.0997 73.5496 81.4648 69.9683 81.4648 69.9683C82.477 69.8904 83.5669 69.034 84.3454 63.3506L84.7347 63.5063L91.8195 66.0755C91.8195 66.0755 91.2745 68.3333 90.9631 70.5132C90.8074 72.3818 90.6517 74.0946 91.041 74.3282Z" fill="#FFB27D"/>
                  <path d="M91.9755 66.0761C91.9755 66.0761 91.4304 68.3338 91.119 70.5138C87.0706 69.8131 85.4356 65.7646 84.8906 63.5068L91.9755 66.0761Z" fill="#ED975D"/>
                  <path d="M92.9881 68.3333C92.9881 68.3333 83.3341 67.0098 84.1905 61.015C85.1248 55.0201 84.424 50.7381 90.6524 51.3609C96.8808 51.9059 97.7373 54.3973 97.893 56.4994C98.1265 58.6015 95.5573 68.5669 92.9881 68.3333Z" fill="#FFB27D"/>
                  <path d="M97.5803 53.2297C97.5803 53.2297 91.3519 58.6017 87.1477 58.9909C82.9435 59.4581 81.3086 58.0567 81.3086 58.0567C81.3086 58.0567 83.7999 55.9546 85.2792 52.6847C85.7463 51.6726 86.6806 51.0497 87.6928 50.9719C90.807 50.6605 96.4903 50.5047 97.5803 53.2297Z" fill="url(#paint4_linear)"/>
                  <path d="M93.7656 52.7621C93.7656 52.7621 95.8677 54.7864 96.4906 56.2656C97.1134 57.667 97.347 61.1705 96.802 62.494C96.802 62.494 100.072 56.1878 98.2034 53.1514C96.257 49.8815 93.7656 52.7621 93.7656 52.7621Z" fill="url(#paint5_linear)"/>
                  <path d="M99.2152 88.6542C98.9038 108.274 99.1374 104.147 96.9574 106.016C96.4124 106.483 95.011 106.717 93.0647 106.872C87.0698 107.262 76.4816 106.249 76.0923 105.159C74.6909 101.345 75.703 100.099 74.5352 94.2598C74.3795 93.6369 74.3016 92.9362 74.0681 92.2356C73.1338 88.1871 72.1996 86.7857 75.6252 80.3237C78.7394 74.4067 81.9315 69.6576 82.2429 69.8133C90.4177 74.6403 91.1183 70.5918 91.1183 70.5918C91.1183 70.5918 99.3709 79.078 99.2152 88.6542Z" fill="url(#paint6_linear)"/>
                  <path d="M91.1191 70.5137C91.1191 70.5137 99.5276 71.9151 100.54 75.5743C101.552 79.2334 107.391 100.566 107.391 100.566C107.391 100.566 103.109 101.344 101.162 99.32C99.2161 97.3737 94.3113 80.9463 94.3113 80.9463L91.1191 70.5137Z" fill="url(#paint7_linear)"/>
                  <path d="M93.1428 106.872C87.148 107.262 76.5597 106.249 76.1704 105.159C74.769 101.345 75.7811 100.099 74.6133 94.2597L77.4939 86.085C77.4939 86.085 77.0268 95.194 79.6739 97.6075C82.3209 100.021 90.6515 102.201 91.975 104.225C92.5978 105.082 92.9871 106.094 93.1428 106.872Z" fill="url(#paint8_linear)"/>
                  <path d="M86.2148 102.979C86.2148 102.979 92.1318 101.655 93.066 102.2C94.0003 102.745 96.336 104.613 96.6474 105.314C96.9589 106.015 94.5453 104.769 93.7667 104.613C93.2218 104.458 91.8983 104.925 91.2754 105.158C88.4726 106.093 86.7598 105.626 86.7598 105.626L86.2148 102.979Z" fill="#FFB27D"/>
                  <path d="M82.2436 69.7354C82.2436 69.7354 74.3023 70.2025 71.4996 74.0952C67.6068 79.5451 59.8991 102.59 64.3369 105.627C69.3196 109.13 86.9927 106.561 86.9927 106.561C86.9927 106.561 87.6155 103.836 86.7591 102.512C85.9806 101.189 72.6674 99.0089 72.0446 98.4639C71.4217 97.841 79.6743 86.4742 79.6743 84.6835C79.6743 82.815 82.2436 69.7354 82.2436 69.7354Z" fill="url(#paint9_linear)"/>
                  <path d="M93.377 103.835C93.377 103.835 94.6226 105.47 95.0119 105.626C95.4012 105.781 95.7126 105.781 95.7126 105.781L94.8562 104.302L93.377 103.835Z" fill="#FFB27D"/>
                  <path d="M93.1426 102.2L96.7239 103.991C96.7239 103.991 96.5681 104.224 96.101 104.224C95.6339 104.224 94.5439 103.757 94.5439 103.757L93.1426 102.2Z" fill="#FFB27D"/>
                  <path d="M95.0117 108.117H123.662L132.226 86.707H103.654L95.0117 108.117Z" fill="#E6E1DC"/>
                  <path d="M123.975 107.261H86.2148V108.117H123.663L123.975 107.261Z" fill="#E6E1DC"/>
                  <path d="M116.423 97.4515C116.656 96.6729 116.189 96.1279 115.488 96.1279C114.71 96.1279 113.931 96.7508 113.776 97.4515C113.542 98.23 114.009 98.775 114.71 98.775C115.411 98.775 116.189 98.1522 116.423 97.4515Z" fill="white"/>
                  <g opacity="0.44">
                  <path d="M155.5 29L143.75 52.5L155.5 76C168.479 76 179 65.4787 179 52.5C179 39.5213 168.479 29 155.5 29Z" fill="#ADA7A7"/>
                  <path d="M132 52.5C132 65.4787 142.521 76 155.5 76V29C142.521 29 132 39.5213 132 52.5Z" fill="#C4C4C4"/>
                  <path d="M155.5 34.5078L146.504 52.5L155.5 70.4922C165.437 70.4922 173.492 62.4368 173.492 52.5C173.492 42.5632 165.437 34.5078 155.5 34.5078Z" fill="#F2F2F2"/>
                  <path d="M137.508 52.5C137.508 62.4368 145.563 70.4922 155.5 70.4922V34.5078C145.563 34.5078 137.508 42.5632 137.508 52.5Z" fill="white"/>
                  <path d="M159.395 54.4473L155.5 50.5527H153.664L155.5 54.4473L157.447 56.3946L159.395 54.4473Z" fill="#ADA7A7"/>
                  <path d="M147.678 42.7314L145.73 44.6787L155.499 54.4477V50.5531L147.678 42.7314Z" fill="#C4C4C4"/>
                  <path d="M163.354 42.6982L155.5 50.5524L153.664 54.4469H155.5L165.301 44.6454L163.354 42.6982Z" fill="#ADA7A7"/>
                  <path d="M151.605 54.4473L153.553 56.3946L155.5 54.4473V50.5527L151.605 54.4473Z" fill="#C4C4C4"/>
                  <path d="M156.877 37.2617H155.5L154.582 38.6387L155.5 40.0156H156.877V37.2617Z" fill="#ADA7A7"/>
                  <path d="M154.123 37.2617H155.5V40.0156H154.123V37.2617Z" fill="#C4C4C4"/>
                  <path d="M156.877 64.9844H155.5L154.582 66.3613L155.5 67.7383H156.877V64.9844Z" fill="#ADA7A7"/>
                  <path d="M154.123 64.9844H155.5V67.7383H154.123V64.9844Z" fill="#C4C4C4"/>
                  <path d="M140.262 53.877V51.123H143.016V53.877H140.262Z" fill="#C4C4C4"/>
                  <path d="M167.984 53.877V51.123H170.738V53.877H167.984Z" fill="#ADA7A7"/>
                  </g>
                  <defs>
                  <linearGradient id="paint0_linear" x1="90.8018" y1="124.525" x2="133.416" y2="124.525" gradientUnits="userSpaceOnUse">
                  <stop offset="4.36879e-07" stop-color="#1A2745"/>
                  <stop offset="1" stop-color="#808797"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear" x1="107.332" y1="137.054" x2="125.028" y2="137.054" gradientUnits="userSpaceOnUse">
                  <stop offset="4.36879e-07" stop-color="#1A2745"/>
                  <stop offset="1" stop-color="#808797"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear" x1="73.4851" y1="127.525" x2="148.641" y2="127.525" gradientUnits="userSpaceOnUse">
                  <stop offset="4.36879e-07" stop-color="#1A2745"/>
                  <stop offset="1" stop-color="#808797"/>
                  </linearGradient>
                  <linearGradient id="paint3_linear" x1="63.1028" y1="68.0984" x2="101.975" y2="68.0984" gradientUnits="userSpaceOnUse">
                  <stop offset="5.5409e-07"/>
                  <stop offset="1" stop-color="#3F1700"/>
                  </linearGradient>
                  <linearGradient id="paint4_linear" x1="81.3784" y1="54.9338" x2="97.6126" y2="54.9338" gradientUnits="userSpaceOnUse">
                  <stop offset="5.5409e-07"/>
                  <stop offset="1" stop-color="#3F1700"/>
                  </linearGradient>
                  <linearGradient id="paint5_linear" x1="93.7364" y1="57.0192" x2="98.7451" y2="57.0192" gradientUnits="userSpaceOnUse">
                  <stop offset="5.5409e-07"/>
                  <stop offset="1" stop-color="#3F1700"/>
                  </linearGradient>
                  <linearGradient id="paint6_linear" x1="73.2214" y1="88.3505" x2="99.2187" y2="88.3505" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  <linearGradient id="paint7_linear" x1="91.1033" y1="85.6267" x2="107.332" y2="85.6267" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  <linearGradient id="paint8_linear" x1="74.56" y1="96.4904" x2="93.1095" y2="96.4904" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  <linearGradient id="paint9_linear" x1="63.0318" y1="88.6256" x2="87.179" y2="88.6256" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF6333"/>
                  <stop offset="1" stop-color="#FFAF60"/>
                  </linearGradient>
                  </defs>
                  </svg>
                  </div>
                <h4><a href="">Prompt Service</a></h4>
                <p>We take the path along with our customers leaving no stones unturned. Experience the best quality service with Gopolicy.</p>
              </div>
            </div>
  
            <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
              <div class="icon-box">
                <div class="icon"><img class="img-fluid" src="assets/images/value_money_2.png" alt=""></div>
                <h4><a href="">Transparent Process</a></h4>
                <p>Ever fallen for a scam? Forget the past and move on with us. We promise transparency, security, and safety throughout the process.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</section>
  <section id="faq" class="faq pb-90">
    <div class="container-fluid">
      <div class="section-title pt-30">
        <h2>Frequently Asked Questions</h2>
        <p class="mb-5">Feel free to ask us more</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-9 col-12">
          <div class="accordion-list">
            <ul>
              <li data-aos="fade-up">
                <a data-toggle="collapse" class="collapse" href="#advisoraccordion-list-1">Does transit insurance cover partial loss? <i
                    class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
                <div id="advisoraccordion-list-1" class="collapse show" data-parent=".accordion-list">
                  <p>
                    Yes, the insurance company offers coverage for partially damaged goods and covers the financial losses that you
                    encounter due to this loss.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <a data-toggle="collapse" href="#advisoraccordion-list-2" class="collapsed">What documents would be needed when settling a claim? <i
                    class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
                <div id="advisoraccordion-list-2" class="collapse" data-parent=".accordion-list">
                  <p>
                    For claim processing, you have to submit the following documents, Survey report, original invoice of goods in transit,
                    landing bill, duly filled and signed claim form, details of shipping, and any other document mentioned by insurance
                    provider.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="200">
                <a data-toggle="collapse" href="#advisoraccordion-list-3" class="collapsed">Are goods covered throughout the journey? <i class="bx bx-plus icon-show"></i><i class="bx bx-minus icon-close"></i></a>
                <div id="advisoraccordion-list-3" class="collapse" data-parent=".accordion-list">
                  <p>
                    Most cargo policies contain warehouse-to-warehouse and extended-cover clauses which insure goods from the time they
                    leave the warehouse at the point of shipment, through the ordinary course of transportation by rail, truck, lighters,
                    steamers, aircraft, or other conveyance, until they are delivered to the warehouse at the final destination. The point
                    of shipment and final delivery may be placed within a country. The goods are also covered during any delays or
                    deviations during the journey that are beyond the control of the insured, although a time limit is sometimes imposed
                    in such cases.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
