import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner"; 
declare var $: any;
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  quereyForm:FormGroup
  claimForm:FormGroup
  quereysubmitted=false;
  claimsubmitted=false;
  QueryTellusInvalid:boolean=false;
  constructor(private router:Router,private fb:FormBuilder,private auth:AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.quereyForm=this.fb.group({
      description:["",[Validators.required]],
      mobile_number:["",[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
    this.claimForm=this.fb.group({
      type_of_insurance:["",[Validators.required]],
      description:["",[Validators.required]],
      mobile_number:["",[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }
  get q() { return this.quereyForm.controls; }
  get c() { return this.claimForm.controls; }
  quereySubmit(){
  this.quereysubmitted=true;
  this.spinner.show(); 
  if (this.quereyForm.invalid) {
    this.spinner.hide();
    return;
  }else{
    this.auth.quereyFormSave(this.quereyForm.value).subscribe(data=>{
      this.spinner.hide();
      if(data.status=="Success"){
        this.quereyForm.reset();
        this.quereysubmitted=false;
        Swal.fire('Success!', 'Successfully Saved', 'success');
      }else{
        this.quereyForm.reset();
        this.quereysubmitted=false;
        Swal.fire('Oops...', 'Something went wrong!', 'error');
      }
    console.log(JSON.stringify(data));
    },error => console.log(error));
  }
  }
  registerClaim(){
    this.claimsubmitted=true;
    this.spinner.show(); 
    if (this.claimForm.invalid) {
      this.spinner.hide(); 
    return;
    }else{
    this.auth.claimFormSave(this.claimForm.value).subscribe(data =>{
      this.spinner.hide(); 
      if(data.status=="Success"){
        this.claimForm.reset();
        this.claimsubmitted=false;
        Swal.fire('Success!', 'Successfully Saved', 'success');
      }else{
        this.claimForm.reset();
        this.claimsubmitted=false;
        Swal.fire('Oops...', 'Something went wrong!', 'error');
      }
      console.log(JSON.stringify(data));
    },error => console.log(error));
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
