import { Component, OnInit} from '@angular/core';
import { Router} from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  constructor(private router:Router,private auth:AuthService) { }
  ngOnInit(): void {

  }
  ourTeamPage() {
    this.router.navigate(['/about'], { queryParams: { page: "OurTeam" } });
  }
  howWeDoItPage() {
    this.router.navigate(['/about'], { queryParams: { page: "HowWeDOIt" } });
  }
  testimonialsPage(){
    this.router.navigate([''], { queryParams: { page: "Testimonials" } }); 
  }
  FAQPage(){
    this.router.navigate([''], { queryParams: { page: "FAQ" } }); 
  }
}
