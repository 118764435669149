<!--Submit Querey Modal Start-->
<div
  class="modal fade bd-example-modal-lg"
  id="submitquerey"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myExtraLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="container">
          <h5
            class="modal-title text-center submit-your-querey mt-3"
            id="exampleModalLabel"
          >
            Submit Your Query
          </h5>
          <form [formGroup]="quereyForm">
            <div class="row justify-content-around my-5">
              <div class="col-lg-11 col-md-11 col-sm-12">
                <div class="form-group mt-2">
                  <label class="submit-querey-label" for="quereydescribe"
                    >Tell us what happened</label
                  >
                  <textarea
                    class="form-control submit-querey-tell-us"
                    id="quereydescribe"
                    name="quereydescribe"
                    formControlName="description"
                    rows="6"
                    placeholder="Please describe"
                  ></textarea>
                  <div
                    class="mt-2 text-left"
                    *ngIf="quereysubmitted && q.description.errors"
                  >
                    <div
                      class="alert-meassge"
                      *ngIf="q.description.errors.required"
                    >
                      Tell us is Required
                    </div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label class="submit-querey-label" for="mobilenumber"
                    >Please provide a mobile number on which we can call
                    you</label
                  >
                  <input
                    class="form-control form-control-lg submit-querey-tell-us"
                    type="text"
                    id="mobilenumber"
                    name="mobilenumber"
                    formControlName="mobile_number"
                    placeholder="XX-XXXXXXXXXX"
                    maxlength="10"
                    (keypress)="numberOnly($event)"
                  />
                  <div
                    class="mt-2 text-left"
                    *ngIf="quereysubmitted && q.mobile_number.errors"
                  >
                    <div
                      class="alert-meassge"
                      *ngIf="q.mobile_number.errors.required"
                    >
                      Mobile Number is Required.
                    </div>
                    <div
                      class="alert-meassge"
                      *ngIf="q.mobile_number.errors.pattern"
                    >
                      Please, Enter 10 digit Mobile Number.
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-submit-querey-popup mt-3"
                  type="submit"
                  (click)="quereySubmit()"
                >
                  Submit form
                </button>
              </div>
            </div>
          </form>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--Submit Querey Modal End-->
<!--Submit Claim Modal Start-->
<div
  class="modal fade bd-example-modal-lg"
  id="submitclaim"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myExtraLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="container">
          <h5
            class="modal-title text-center submit-your-querey mt-3"
            id="exampleModalLabel"
          >
            Submit Claim Request
          </h5>
          <form [formGroup]="claimForm">
            <div class="row justify-content-around my-5">
              <div class="col-lg-11 col-md-11 col-sm-12">
                <div class="form-group mt-2">
                  <label class="submit-querey-label" for="type_of_insurance"
                    >Type of Insurance</label
                  >
                  <select
                    class="form-control form-control-lg submit-querey-tell-us"
                    id="type_of_insurance"
                    name="type_of_insurance"
                    formControlName="type_of_insurance"
                  >
                    <option>Motor Insurance</option>
                    <option>Health Insurance</option>
                    <option>Life Insurance</option>
                    <option>Home Insurance</option>
                    <option>Travel Insurance</option>
                    <option>Corporate Insurance</option>
                    <option>Marine Insurance</option>
                  </select>
                  <div
                    class="mt-2 text-left"
                    *ngIf="claimsubmitted && c.type_of_insurance.errors"
                  >
                    <div
                      class="alert-meassge"
                      *ngIf="c.type_of_insurance.errors.required"
                    >
                      Type of Insurance is Required
                    </div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label class="submit-querey-label" for="claimdescription"
                    >Tell us what happened</label
                  >
                  <textarea
                    class="form-control submit-querey-tell-us"
                    id="claimdescription"
                    name="claimdescription"
                    formControlName="description"
                    rows="3"
                    placeholder="Please describe the incident"
                  ></textarea>
                  <div
                    class="mt-2 text-left"
                    *ngIf="claimsubmitted && c.description.errors"
                  >
                    <div
                      class="alert-meassge"
                      *ngIf="c.description.errors.required"
                    >
                      Tell us is Required
                    </div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label class="submit-querey-label" for="mobile"
                    >Please provide a mobile number on which we can call
                    you</label
                  >
                  <input
                    class="form-control form-control-lg submit-querey-tell-us"
                    type="text"
                    id="mobile"
                    name="mobile"
                    formControlName="mobile_number"
                    placeholder="XX-XXXXXXXXXX"
                    maxlength="10"
                    (keypress)="numberOnly($event)"
                  />
                  <div
                    class="mt-2 text-left"
                    *ngIf="claimsubmitted && c.mobile_number.errors"
                  >
                    <div
                      class="alert-meassge"
                      *ngIf="c.mobile_number.errors.required"
                    >
                      Mobile Number is Required
                    </div>
                    <div
                      class="alert-meassge"
                      *ngIf="c.mobile_number.errors.pattern"
                    >
                      Please, Enter 10 digit Mobile Number.
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-submit-querey-popup mt-3"
                  type="submit"
                  (click)="registerClaim()"
                >
                  Register Claim
                </button>
              </div>
            </div>
          </form>
        </div>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!--Submit Claim Modal End-->
<div class="container-fluid d-flex justify-content-between header-bg-dark">
  <div class="contact-info d-flex align-items-center">
    <a
      class="txt-claim pl-lg-3 pl-md-0 pl-sm-0"
      role="button"
      data-toggle="modal"
      data-target="#submitclaim"
      >Raise Claim</a
    >
  </div>
  <div class="social-links">
    <div class="dropdown">
      <a
        class="btn txt-support"
        role="button"
        id="dropdownMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <svg
          class="support-icon"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.8865 12.7602C17.2365 11.9102 17.4265 11.0002 17.4265 10.0002C17.4265 9.28019 17.3165 8.59019 17.1265 7.95019C16.4765 8.10019 15.7965 8.18019 15.0865 8.18019C13.6325 8.18175 12.1994 7.83371 10.908 7.16541C9.61668 6.49711 8.50494 5.52815 7.6665 4.34019C6.7696 6.51036 5.07763 8.25599 2.9365 9.22019C2.8965 9.47019 2.8965 9.74019 2.8965 10.0002C2.8965 10.9549 3.08455 11.9003 3.4499 12.7823C3.81525 13.6643 4.35076 14.4658 5.02584 15.1409C6.38923 16.5042 8.23838 17.2702 10.1665 17.2702C11.2165 17.2702 12.2265 17.0402 13.1365 16.6302C13.7065 17.7202 13.9665 18.2602 13.9465 18.2602C12.3065 18.8102 11.0365 19.0802 10.1665 19.0802C7.7465 19.0802 5.4365 18.1302 3.7365 16.4202C2.7025 15.3894 1.93387 14.1232 1.4965 12.7302H0.166504V8.18019H1.2565C1.58675 6.5727 2.346 5.0846 3.45369 3.87378C4.56139 2.66296 5.97621 1.7746 7.54803 1.30295C9.11985 0.8313 10.79 0.793963 12.3814 1.1949C13.9727 1.59583 15.4258 2.42008 16.5865 3.58019C17.8467 4.83544 18.7063 6.43629 19.0565 8.18019H20.1665V12.7302H20.1065L16.5465 16.0002L11.2465 15.4002V13.7302H16.0765L16.8865 12.7602ZM7.4365 9.77019C7.7365 9.77019 8.0265 9.89019 8.2365 10.1102C8.44755 10.323 8.56597 10.6105 8.56597 10.9102C8.56597 11.2099 8.44755 11.4974 8.2365 11.7102C8.0265 11.9202 7.7365 12.0402 7.4365 12.0402C6.8065 12.0402 6.2965 11.5402 6.2965 10.9102C6.2965 10.2802 6.8065 9.77019 7.4365 9.77019ZM12.8865 9.77019C13.5165 9.77019 14.0165 10.2802 14.0165 10.9102C14.0165 11.5402 13.5165 12.0402 12.8865 12.0402C12.2565 12.0402 11.7465 11.5402 11.7465 10.9102C11.7465 10.6078 11.8666 10.3179 12.0804 10.1041C12.2942 9.8903 12.5842 9.77019 12.8865 9.77019Z"
            fill="#FAFCFE"
          />
        </svg>
        Support
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <a class="dropdown-item dropdown-menu-email mt-2"
          ><svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66634 2.6665H13.333C14.0663 2.6665 14.6663 3.2665 14.6663 3.99984V11.9998C14.6663 12.7332 14.0663 13.3332 13.333 13.3332H2.66634C1.93301 13.3332 1.33301 12.7332 1.33301 11.9998V3.99984C1.33301 3.2665 1.93301 2.6665 2.66634 2.6665Z"
              stroke="#FF6333"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.6663 4L7.99967 8.66667L1.33301 4"
              stroke="#FF6333"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            /></svg
          >&nbsp;&nbsp;&nbsp; <span class="txt-mail">Email ID</span><br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
            class="txt-mailid mt-2"
            >support@gopolicy.com</span
          ></a
        >
        <a class="dropdown-item dropdown-menu-email mt-2"
          ><svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8915 7.05809C14.6382 7.20378 15.3245 7.56898 15.8625 8.10696C16.4004 8.64494 16.7656 9.33121 16.9113 10.0779L13.8915 7.05809ZM13.8915 4C15.4429 4.17235 16.8896 4.8671 17.9941 5.97018C19.0985 7.07326 19.7951 8.5191 19.9694 10.0703L13.8915 4ZM19.2049 16.1712V18.4648C19.2058 18.6777 19.1621 18.8884 19.0768 19.0835C18.9915 19.2786 18.8664 19.4537 18.7095 19.5977C18.5526 19.7416 18.3674 19.8512 18.1657 19.9194C17.964 19.9876 17.7503 20.013 17.5382 19.9938C15.1857 19.7382 12.9259 18.9343 10.9404 17.6467C9.0932 16.4729 7.52709 14.9068 6.35329 13.0596C5.06123 11.0651 4.25715 8.79431 4.00621 6.43118C3.9871 6.21976 4.01223 6.00669 4.07998 5.80551C4.14774 5.60434 4.25664 5.41948 4.39976 5.2627C4.54287 5.10592 4.71706 4.98066 4.91124 4.89489C5.10542 4.80912 5.31533 4.76472 5.5276 4.76452H7.82117C8.1922 4.76087 8.55189 4.89226 8.83321 5.13419C9.11453 5.37613 9.29828 5.71211 9.35021 6.0795C9.44702 6.81349 9.62655 7.53418 9.88538 8.22781C9.98824 8.50145 10.0105 8.79884 9.94953 9.08474C9.88855 9.37065 9.74689 9.63308 9.54134 9.84095L8.5704 10.8119C9.65874 12.7259 11.2435 14.3107 13.1575 15.399L14.1285 14.4281C14.3363 14.2225 14.5988 14.0809 14.8847 14.0199C15.1706 13.9589 15.468 13.9812 15.7416 14.084C16.4352 14.3429 17.1559 14.5224 17.8899 14.6192C18.2613 14.6716 18.6005 14.8587 18.8429 15.1448C19.0854 15.431 19.2142 15.7962 19.2049 16.1712Z"
              stroke="#FF6333"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          &nbsp; <span class="txt-mail">Sales Enquiries</span> <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span class="txt-mailid">+91 893 993 3533</span></a
        >
        <a class="dropdown-item dropdown-menu-email mt-2"
          ><svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M16.9642 2.71522L10.8571 2.71387V4.07102H16.9642C17.3386 4.07102 17.6428 4.3765 17.6428 4.75092V10.1782C17.6428 10.5526 17.3386 10.8567 16.9642 10.8567H2.59767L5.70163 7.75278L4.7421 6.79326L0 11.5353L4.75002 16.2853L5.70955 15.3258L2.59767 12.2139H16.9643C18.0869 12.2139 19 11.3007 19 10.1782V4.75092C19 3.62834 18.0868 2.71522 16.9642 2.71522Z"
                fill="#FF6333"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="19" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
          &nbsp;&nbsp;&nbsp; Support or Grievance</a
        >
        <button
          class="btn btn-block btn-submit-querey mt-2"
          data-toggle="modal"
          data-target="#submitquerey"
        >
          Submit Query
        </button>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink=""
      ><img
        src="assets/images/Gopolicy_Logo.png"
        class="img-fluid navbar-img"
        alt=""
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse mx-lg-3 mx-md-3 mx-sm-0 mx-0"
      id="navbarSupportedContent"
    >
      <ul class="navbar-nav mr-auto">
        <li class="nav-item nav-padding dropdown">
          <a
            class="nav-link active"
            routerLink=""
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Insurance
          </a>
          <ul
            id="dropdownnav"
            class="dropdown-menu full-width-dropdown-menu bg-dropdown-menu justify-content-center"
            role="menu"
          >
            <div class="row justify-content-center">
              <div class="col-lg-10 col-12">
                <div class="row py-lg-5">
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="motorinsure" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 0C5.83177 0 0 5.83177 0 13C0 20.1682 5.83177 26 13 26C20.1682 26 26 20.1682 26 13C26 5.83177 20.1682 0 13 0ZM3.34664 9.48553C4.0074 7.55391 5.26698 5.89205 6.98923 4.67959C8.7425 3.44536 10.821 2.79292 13 2.79292C15.179 2.79292 17.2575 3.44536 19.0108 4.67959C20.733 5.89205 21.9926 7.55391 22.6534 9.48553L22.8833 10.1577H3.1167L3.34664 9.48553ZM10.482 22.997C8.39531 22.5554 6.48116 21.4097 5.09224 19.771C3.68697 18.1131 2.87264 16.0068 2.79927 13.8402L2.77789 13.2094L3.39859 13.3235C7.81996 14.1366 11.0568 17.9941 11.0949 22.4958L11.1002 23.1278L10.482 22.997ZM13.8722 16.4672C13.5859 16.5854 13.3025 16.6428 13.0057 16.6428C12.3003 16.6428 11.3238 16.2727 10.8929 15.2297C10.5948 14.5082 10.7292 13.8821 10.8945 13.4839C11.0598 13.0857 11.4084 12.5487 12.1302 12.2506C12.4167 12.1322 12.7002 12.0747 12.997 12.0747C13.7022 12.0747 14.6787 12.4448 15.1097 13.4877C15.5899 14.6503 15.0348 15.987 13.8722 16.4672ZM20.9078 19.7711C19.5188 21.4097 17.6047 22.5554 15.5181 22.997L14.8998 23.1278L14.9052 22.4958C14.9433 17.9943 18.18 14.1368 22.6014 13.3237L23.2221 13.2095L23.2007 13.8403C23.1273 16.0069 22.313 18.1132 20.9078 19.7711Z"
                          fill="#B3B3B3"
                        />
                      </svg>
                      Motor Insurance</a
                    >
                  </div>
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-md-0 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="healthinsure" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        id="Layer_1"
                        enable-background="new 0 0 512.063 512.063"
                        width="26"
                        height="26"
                        viewBox="0 0 512.063 512.063"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m285.971 236.113h48.26v29.87h-48.26c-8.29 0-15 6.72-15 15v48.27h-29.87v-48.27c0-8.28-6.71-15-15-15h-48.27v-29.87h48.27c8.29 0 15-6.71 15-15v-48.27h29.87v48.27c0 8.289 6.71 15 15 15z"
                          fill="#B3B3B3"
                        />
                        <path
                          d="m473.011 138.883-.01-.49c-.24-12.76-.47-24.82-.47-36.42 0-17.58-14.3-31.87-31.87-31.87-68 0-119.61-19.39-162.42-61.02-12.46-12.11-31.98-12.11-44.44 0-42.8 41.63-94.41 61.02-162.4 61.02-17.57 0-31.87 14.29-31.87 31.87 0 11.84-.23 24.02-.48 36.92-1.15 60.2-2.58 135.12 22.56 203.13 13.83 37.4 34.76 69.89 62.22 96.59 31.28 30.41 72.24 54.53 121.75 71.69 3.37 1.17 6.88 1.76 10.44 1.76s7.08-.59 10.44-1.76c49.51-17.16 90.48-41.28 121.76-71.69 27.46-26.7 48.39-59.2 62.22-96.59 25.15-68.02 23.72-142.94 22.57-203.14zm-108.78 142.099c0 8.28-6.71 15-15 15h-48.26v48.27c0 8.28-6.72 15-15 15h-59.87c-8.28 0-15-6.72-15-15v-48.27h-48.27c-8.29 0-15-6.72-15-15v-59.87c0-8.28 6.71-15 15-15h48.27v-48.27c0-8.28 6.72-15 15-15h59.87c8.28 0 15 6.72 15 15v48.27h48.26c8.29 0 15 6.72 15 15z"
                          fill="#B3B3B3"
                        />
                      </svg>
                      Health Insurance</a
                    >
                  </div>
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-md-0 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="lifeinsure" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.9926 2.15759C7.32539 -2.7992 0.119624 1.55598 0.00146524 7.46012C-0.0286458 8.96481 0.405704 10.4305 1.27029 11.7817H6.87429L7.87105 10.1204C8.16048 9.63807 8.8609 9.62253 9.16674 10.1034L11.2698 13.4081L14.3354 6.93651C14.6027 6.37181 15.3995 6.35389 15.6943 6.90147L18.3222 11.7817H24.7149C29.4505 4.38042 20.0914 -4.05121 12.9926 2.15759Z"
                          fill="#B3B3B3"
                        />
                        <path
                          d="M17.197 12.9043L15.0676 8.94971L12.0565 15.3065C11.8003 15.8474 11.0468 15.8943 10.7255 15.3894L8.54364 11.9608L7.95904 12.9351C7.82138 13.1645 7.57348 13.3048 7.30594 13.3048H2.47559C2.627 13.4633 1.82 12.6579 12.4558 23.2386C12.7528 23.5342 13.233 23.5342 13.5301 23.2386C24.0023 12.8207 23.3592 13.463 23.5103 13.3048H17.8676C17.5874 13.3049 17.3299 13.151 17.197 12.9043Z"
                          fill="#B3B3B3"
                        />
                      </svg>
                      Life Insurance</a
                    >
                  </div>
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-md-0 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="homeinsure" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        width="26"
                        height="26"
                        viewBox="0 0 25 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.6791 0.248276L24.1825 12.6621C24.5963 13.0759 24.2653 13.8207 23.6032 13.8207H20.3756V23.2552C20.3756 23.669 20.0446 24 19.6308 24H15.0791C14.6653 24 14.3342 23.669 14.3342 23.2552V19.0345C14.3342 16.3034 10.0308 16.3034 10.0308 19.0345V23.2552C10.0308 23.669 9.69975 24 9.28595 24H4.73423C4.32043 24 3.9894 23.669 3.9894 23.2552V13.8207H0.761814C0.0997448 13.8207 -0.23129 13.0759 0.182503 12.6621L11.686 0.248276C11.9342 -0.0827586 12.4308 -0.0827586 12.6791 0.248276Z"
                          fill="#B3B3B3"
                        />
                      </svg>
                      <span>Home Insurance</span></a
                    >
                  </div>
                </div>
                <div class="row pb-lg-5 justify-content-center">
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-md-0 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="travelinsure" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.2654 0.734075C24.2717 -0.259612 22.6555 -0.243007 21.6825 0.770943L16.3562 6.32118L3.35821 2.01965L0.629687 4.74823L11.464 11.4191L5.98183 17.1317L2.46619 16.5545L0 19.0207L5.16852 20.831L6.97882 25.9995L9.44501 23.5333L8.86783 20.0177L14.5804 14.5356L21.2513 25.3698L23.9799 22.6413L19.6784 9.64324L25.2285 4.31705C26.2425 3.34398 26.2591 1.72781 25.2654 0.734075Z"
                          fill="#B3B3B3"
                        />
                      </svg>
                      Travel Insurance</a
                    >
                  </div>
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-md-0 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="corporateinsure" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0)">
                          <path
                            d="M16.2493 7.04199C15.6513 7.04199 15.166 6.55666 15.166 5.95866V4.33366H10.8327V5.95866C10.8327 6.55666 10.3473 7.04199 9.74935 7.04199C9.15135 7.04199 8.66602 6.55666 8.66602 5.95866V4.33366C8.66602 3.13874 9.63776 2.16699 10.8327 2.16699H15.166C16.3609 2.16699 17.3327 3.13874 17.3327 4.33366V5.95866C17.3327 6.55666 16.8473 7.04199 16.2493 7.04199Z"
                            fill="#B3B3B3"
                          />
                          <path
                            d="M13.7692 16.6617C13.5742 16.7375 13.2925 16.7917 13 16.7917C12.7075 16.7917 12.4258 16.7375 12.1658 16.64L0 12.5884V20.8542C0 22.5009 1.3325 23.8334 2.97917 23.8334H23.0208C24.6675 23.8334 26 22.5009 26 20.8542V12.5884L13.7692 16.6617Z"
                            fill="#B3B3B3"
                          />
                          <path
                            d="M26 8.39616V10.877L13.26 15.1237C13.1733 15.1562 13.0867 15.167 13 15.167C12.9133 15.167 12.8267 15.1562 12.74 15.1237L0 10.877V8.39616C0 6.74949 1.3325 5.41699 2.97917 5.41699H23.0208C24.6675 5.41699 26 6.74949 26 8.39616Z"
                            fill="#B3B3B3"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="26" height="26" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      Corporate Insurance</a
                    >
                  </div>
                  <div
                    class="col-lg-3 col-md-12 col-sm-12 col-12 mt-2 mt-md-0 text-lg-center text-md-left text-sm-left text-left"
                  >
                    <a routerLink="transitinsurance" class="tab-description">
                      <svg
                        class="mr-3 ml-2"
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.4644 16.0148L19.4405 17.9322C17.951 19.0958 16.6351 20.5389 14.4208 20.5389C14.4608 20.5389 14.4498 14.9338 14.435 11.1481H16.1872V10.1149H14.4318C14.4279 9.33269 14.4253 8.68086 14.4227 8.2646C16.1549 7.69732 17.4102 6.12649 17.4102 4.25363C17.4102 1.90449 15.4476 0 13.0287 0C10.6086 0 8.64733 1.90449 8.64733 4.25363C8.64733 6.09681 9.8645 7.65408 11.5573 8.24072V10.1149H9.81093V11.1481H11.5573L11.5631 20.4924C9.24688 20.4924 7.89354 19.1668 6.41048 18.0658L8.52406 16.0142L0.854492 14.8842L2.01874 22.3253L4.05746 20.3479C5.71929 21.6902 7.90128 23.5308 12.1614 23.736L12.5492 26H13.4495L13.8393 23.7322C18.0187 23.5011 20.1504 21.6263 21.8083 20.2285L24.0413 22.3937L25.1443 14.8835L17.4644 16.0148ZM10.7687 4.18716C10.7687 2.97709 11.7806 1.99419 13.0287 1.99419C14.2769 1.99419 15.2875 2.97709 15.2875 4.18716C15.2875 5.39787 14.2769 6.38012 13.0287 6.38012C11.7812 6.38141 10.7687 5.39787 10.7687 4.18716Z"
                          fill="#B3B3B3"
                        />
                      </svg>
                      Marine Insurane</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </li>
        <li class="nav-item nav-padding">
          <a class="nav-link" routerLink="about">About Us</a>
        </li>
        <li class="nav-item nav-padding">
          <a class="nav-link" routerLink="advisor">
            Become Insurance Advisor
          </a>
        </li>
      </ul>
      <div class="form-inline">
        <a
          class="nav-link"
          id="login"
          href="https://pos.gopolicy.com/#/"
          target="_blank"
          >Log In</a
        >
      </div>
    </div>
  </div>
</nav>
<ngx-spinner
  bdColor="rgba(51, 51, 51, 0.8)"
  size="default"
  type="ball-spin-clockwise"
>
  <p style="color: white; font-family: Avenir-Roman">Please Wait....</p>
</ngx-spinner>
