import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner"; 
import { Router } from '@angular/router';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  careerForm:FormGroup;
  submitted = false;
  base64textString:any;
  fileformaterrormesg=false;

  constructor(private router:Router,private formBuilder:FormBuilder,private auth:AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  this.careerForm=this.formBuilder.group({
    full_name:["", Validators.required],
    email_id:["", [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
    mobile_number:["", [Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    interested_in:["", Validators.required],
    work_experience:["", Validators.required],
    resume_path:["",Validators.required],
    filetype:[""]
  });
  }
  get f() { return this.careerForm.controls; }

  careerSubmit() {
    this.submitted = true;
    this.spinner.show();
    if (this.careerForm.invalid) {
      this.spinner.hide();
      return;
    }else{
      this.auth.careerFormSave(this.careerForm.value).subscribe(data =>{
        this.spinner.hide();
        if(data.status=="Success"){
          this.careerForm.reset();
          this.submitted=false;
          Swal.fire('Success!', 'Successfully Saved', 'success');
        }else{
          this.careerForm.reset();
          this.submitted=false;
          Swal.fire('Oops...', 'Something went wrong!', 'error');
        }
      console.log(JSON.stringify(data));
      },error =>console.log(error))
    }
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

handleFileSelect(evt){
  var files = evt.target.files;
  console.log(evt.target.files[0].name);
  var ext = evt.target.files[0].name.split('.').pop();
  console.log(ext);
  if((ext=="doc") || (ext=="pdf")){
    this.careerForm.controls['filetype'].setValue(ext);
    this.fileformaterrormesg=false;
    var file = files[0];
    console.log(JSON.stringify(files));
    if (files && file) {
    var reader = new FileReader();
    reader.onload =this.handleReaderLoaded.bind(this);
    reader.readAsBinaryString(file);
   }
   }else{
    this.fileformaterrormesg=true;
   }
}

handleReaderLoaded(readerEvt) {
 var binaryString = readerEvt.target.result;
        this.base64textString= btoa(binaryString);
        this.careerForm.controls['resume_path'].setValue(this.base64textString);
      // console.log(btoa(binaryString));
      // console.log(this.base64textString);
}

}
