import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './view/home/home.component';
import { AboutComponent } from './view/about/about.component';
import { HeaderComponent } from './view/header/header.component';
import { FooterComponent } from './view/footer/footer.component';
import { AdvisorComponent } from './view/advisor/advisor.component';
import { MotorinsuranceComponent } from './view/motorinsurance/motorinsurance.component';
import { HomeinsuranceComponent } from './view/homeinsurance/homeinsurance.component';
import { TravelinsuranceComponent } from './view/travelinsurance/travelinsurance.component';
import { HealthinsuranceComponent } from './view/healthinsurance/healthinsurance.component';
import { SmeinsuranceComponent } from './view/smeinsurance/smeinsurance.component';
import { CorporateinsureComponent } from './view/corporateinsure/corporateinsure.component';
import { LifeinsuranceComponent } from './lifeinsurance/lifeinsurance.component';
import { CareersComponent } from './view/careers/careers.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TransitinsuranceComponent } from './view/transitinsurance/transitinsurance.component';
import { NgxSpinnerModule } from "ngx-spinner"; 

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    HeaderComponent,
    FooterComponent,
    AdvisorComponent,
    MotorinsuranceComponent,
    HomeinsuranceComponent,
    TravelinsuranceComponent,
    HealthinsuranceComponent,
    SmeinsuranceComponent,
    CorporateinsureComponent,
    LifeinsuranceComponent,
    CareersComponent,
    TransitinsuranceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
