import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LifeinsuranceComponent } from './lifeinsurance/lifeinsurance.component';
import { AboutComponent } from './view/about/about.component';
import { AdvisorComponent } from './view/advisor/advisor.component';
import { CareersComponent } from './view/careers/careers.component';
import { CorporateinsureComponent } from './view/corporateinsure/corporateinsure.component';
import { HealthinsuranceComponent } from './view/healthinsurance/healthinsurance.component';
import { HomeComponent } from './view/home/home.component';
import { HomeinsuranceComponent } from './view/homeinsurance/homeinsurance.component';
import { MotorinsuranceComponent } from './view/motorinsurance/motorinsurance.component';
import { SmeinsuranceComponent } from './view/smeinsurance/smeinsurance.component';
import { TransitinsuranceComponent } from './view/transitinsurance/transitinsurance.component';
import { TravelinsuranceComponent } from './view/travelinsurance/travelinsurance.component';

const routes: Routes = [
  { path: '', redirectTo: '*', pathMatch: 'full' },
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path:'advisor', component: AdvisorComponent},
  { path:'motorinsure', component: MotorinsuranceComponent},
  { path:'homeinsure', component: HomeinsuranceComponent},
  {path:'travelinsure',component: TravelinsuranceComponent},
  {path:'healthinsure',component: HealthinsuranceComponent},
  {path:'smeinsure',component: SmeinsuranceComponent},
  {path:'corporateinsure',component: CorporateinsureComponent},
  {path:'lifeinsure',component: LifeinsuranceComponent},
  {path:'careers',component: CareersComponent},
  {path:"transitinsurance",component: TransitinsuranceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
